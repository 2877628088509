import { DEFAULT_CURRENCY, DEFAULT_LANG, KEYS } from "./constant";
import { LocalStorage, SessionStorage } from "./storage";
import moment from "moment";
import { dateDisplay, dayDiff, getDeviceType } from "./utils";
// import { PREFIX } from "../components/common/Select";
import { PREFIX } from "./utils";

export const getRoomListRequest = ({
  source_page = "room_suites",
  currencyData = DEFAULT_CURRENCY,
  roomCode = "",
  rateCode = ""
}) => {
  let configData = LocalStorage.getJSON(KEYS.CONFIG);
  if (configData?.site_id === undefined) {
    return false;
  }

  let currentRoom = LocalStorage.get(KEYS.CURRENT_SELECTED_ROOM);
  if (currentRoom === "" || currentRoom === null || currentRoom === false) {
    LocalStorage.set(KEYS.CURRENT_SELECTED_ROOM, 1);
  }

  let party = [];
  let partyData = SessionStorage.getJSON(KEYS.ROOM_DATA);
  if (partyData?.length) {
    party = partyData.map(item => {
      return {
        child: item?.childrens?.length ? item.childrens.length : 0,
        room: item.room,
        adults: item.adults
      };
    });
  }

  let lauguageParam = LocalStorage.get(KEYS.I18_NEXT_LNG) == "false" ? "en" : LocalStorage.get(KEYS.I18_NEXT_LNG);

  let data = {
    start_date:
      SessionStorage.get(KEYS.CHECK_IN_DATE) ||
      dateDisplay(moment().add(1, "days")),
    end_date:
      SessionStorage.get(KEYS.CHECKOUT_DATE) ||
      dateDisplay(moment().add(2, "days")),
    party,
    source_type: "ibe",
    language: lauguageParam || DEFAULT_LANG,
    currency: currencyData,
    site_id: configData.site_id,
    customer_id: null,
    customer_type: "Customer",
    agent_id: "",
    code_used: SessionStorage.get(KEYS.BOOKING_CODE)
      ? SessionStorage.get(KEYS.BOOKING_CODE)
      : "",
    code_type: SessionStorage.get(KEYS.BOOKING_CODE) ? "2" : "",
    corporatecompanyid: null,
    customermembershiptypeid: null,
    user_share_client_id: "",
    user_country_id: 0,
    device_type: getDeviceType(),
    device_os: "", //window.userAgentData.platform,
    allow_average_price: 1,
    previous_day_threshold_time: "",
    save_search: 0,
    deals_packages: false,
    senior_rate: false,
    allow_tripadvisor: 0,
    last_res: true,
    directRoomCodes: null,
    directRoomId: null,
    membership_type: "",
    is_corporate_enabled: 0,
    special_offer: false,
    flexi_check: false,
    ignore_same_day: 1,
    allow_same_day: 0,
    customer_data: [],
    show_public_rates: null,
    allow_hold_reservation: 0,
    club_enabled: false,
    loyalty_club: {},
    hotel_id: SessionStorage.get(KEYS.HOTEL_ID),
    source_page,
    http_user_agent: window.userAgent,
    user_ip: 0,
    ip: 0,
    user_session_id: "",
    mobile_device: "", //window.userAgentData.mobile,
    user_browser: window.userAgent,
    user_os: window.userAgent,
    is_allow_inventory_pooling: configData?.is_allow_inventory_pooling || 0,
  };
  if (roomCode) {
    data.directRoomCodes = roomCode
  }
  if (rateCode) {
    data.directRateCodes = rateCode
  }

  return document?.cookie?.includes("force_inventory_reduce=1")?{...data,force_inventory_reduce:1}:data;
};

export const getPolicyRequest = () => {
  let configData = LocalStorage.getJSON(KEYS.CONFIG) || [];
  if (configData?.site_id === undefined) {
    return false;
  }
  let data = {
    site_id: configData.site_id,
    hotel_id: SessionStorage.get(KEYS.HOTEL_ID),
    customer_id: null,
    room_id: "",
    language: LocalStorage.get(KEYS.I18_NEXT_LNG) || DEFAULT_LANG,
    rate_id: "",
    check_in:
      SessionStorage.get(KEYS.CHECK_IN_DATE) ||
      dateDisplay(moment().add(1, "days")),
    check_out:
      SessionStorage.get(KEYS.CHECKOUT_DATE) ||
      dateDisplay(moment().add(2, "days")),
    samedays: configData.allow_same_day_booking,
    sameday_rate_all_time: configData.allow_same_day_rate_all_time,
    sameday_override_policy: configData.allow_same_day_override_policy,
    site_name: configData.site_title,
    substitute_room: configData.allow_room_substitute,
    hotel_currency: LocalStorage.get(KEYS.SELECTED_CURRENCY),
    policy_time_format: configData.policy_time_format,
    dateformat: configData.dateformat,
    channel_manager: "RVNG",
    customer_type: 1,
    company_id: 0,
    hotel_timezone: "Asia/Kolkata",
    user_ip: 0,
    ip: 0,
    user_session_id: "",
    mobile_device: "", //window.userAgentData.mobile,
    user_browser: window.userAgent,
    user_os: window.userAgent,
    http_user_agent: window.userAgent,
    client_id: configData.client_id
  };
  return data;
};

export const getCheckoutRequest = () => {
  let configData = LocalStorage.getJSON(KEYS.CONFIG) || [];
  if (configData?.site_id === undefined) {
    return false;
  }
  const cartData = SessionStorage.getJSON(KEYS.ROOM_DATA);
  const roomData = cartData?.length
    ? cartData?.map(detail => {
      const roomRate = detail?.price
        ? Object.values(detail.price).reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        ) / dayDiff()
        : 0;
      return {
        room_id: detail?.room_info.room_id,
        rate_id: detail?.room_info.rate_id,
        room_count: 1,
        room_rate: roomRate,
        adult: detail?.adults || 0,
        child: detail?.childrens?.length || 0,
        childages: [],
        addon_ids: []
      };
    })
    : [];

  let data = {
    site_id: configData.site_id,
    language: LocalStorage.get(KEYS.I18_NEXT_LNG) || DEFAULT_LANG,
    hotel_id: SessionStorage.get(KEYS.HOTEL_ID),
    customer_id: 0,
    check_in:
      SessionStorage.get(KEYS.CHECK_IN_DATE) ||
      dateDisplay(moment().add(1, "days")),
    check_out:
      SessionStorage.get(KEYS.CHECKOUT_DATE) ||
      dateDisplay(moment().add(2, "days")),
    currency: LocalStorage.get(KEYS.SELECTED_CURRENCY),
    user_currency: LocalStorage.get(KEYS.SELECTED_CURRENCY),
    samedays: configData.allow_same_day_booking,
    sameday_override_policy: configData.allow_same_day_override_policy,
    site_name: configData.site_title,
    substitute_room: configData.allow_room_substitute,
    policy_time_format: configData.policy_time_format,
    dateformat: configData.dateformat,
    customer_type: 1,
    company_id: 0,
    addon_ids: [],
    code: SessionStorage.get(KEYS.BOOKING_CODE)
      ? SessionStorage.get(KEYS.BOOKING_CODE)
      : "",
    code_type: SessionStorage.get(KEYS.BOOKING_CODE) ? "2" : "",
    allow_hold_reservation: 0,
    loyalty_club_id: "",
    rooms: roomData,
    user_ip: 0,
    ip: 0,
    user_session_id: "",
    http_user_agent: window.userAgent,
    mobile_device: "", //window.userAgentData.mobile,
    user_browser: window.userAgent,
    user_os: window.userAgent,
    client_id: configData.client_id
  };
  return data;
};

export const getAddonRequest = () => {
  let configData = LocalStorage.getJSON(KEYS.CONFIG) || [];
  if (configData?.site_id === undefined) {
    return false;
  }

  const cartData = SessionStorage.getJSON(KEYS.ROOM_DATA);
  const roomData = cartData.map(detail => {
    return {
      room_id: detail.room_info.room_id,
      rate_id: detail.room_info.rate_id,
      adult: detail.adults || 0,
      child: detail?.childrens?.length || 0,
      childages: []
    };
  });

  let data = {
    site_id: configData.site_id,
    language: LocalStorage.get(KEYS.I18_NEXT_LNG) || DEFAULT_LANG,
    user_currency: LocalStorage.get(KEYS.SELECTED_CURRENCY),
    hotel_id: SessionStorage.get(KEYS.HOTEL_ID),
    check_in:
      SessionStorage.get(KEYS.CHECK_IN_DATE) ||
      dateDisplay(moment().add(1, "days")),
    check_out:
      SessionStorage.get(KEYS.CHECKOUT_DATE) ||
      dateDisplay(moment().add(2, "days")),
    rooms: roomData,
    user_ip: 0,
    user_session_id: "",
    associated_ibe: false
  };
  return data;
};

export const getCountryRequest = () => {
  let configData = LocalStorage.getJSON(KEYS.CONFIG) || [];
  if (configData?.site_id === undefined) {
    return false;
  }

  let data = {
    site_id: configData.site_id,
    getdialcode: 1,
    user_ip: 0,
    ip: 0,
    user_session_id: "",
    http_user_agent: window.userAgent,
    mobile_device: "", //window.userAgentData.mobile,
    user_browser: window.userAgent,
    user_os: window.userAgent,
    language: LocalStorage.get(KEYS.I18_NEXT_LNG) || DEFAULT_LANG,
  };
  return data;
};

// export const getConfirmationRequest = ({
//   source_page = "confirmation",
//   currencyData = DEFAULT_CURRENCY
// }) => {
//   let configData = LocalStorage.getJSON(KEYS.CONFIG);
//   if (configData?.site_id === undefined) {
//     return false;
//   }

//   let currentRoom = LocalStorage.get(KEYS.CURRENT_SELECTED_ROOM);
//   if (currentRoom === "" || currentRoom === null || currentRoom === false) {
//     LocalStorage.set(KEYS.CURRENT_SELECTED_ROOM, 1);
//   }

//   let party = [];
//   let partyData = SessionStorage.get(KEYS.ROOM_DATA);
//   if (partyData?.length) {
//     const { childrens, ...rest } = JSON.parse(
//       SessionStorage.get(KEYS.ROOM_DATA)
//     )[0];
//     party = [{ child: childrens.length, ...rest }];
//   }

//   let data = {
//     start_date:
//       SessionStorage.get(KEYS.CHECK_IN_DATE) ||
//       dateDisplay(moment().add(1, "days")),
//     end_date:
//       SessionStorage.get(KEYS.CHECKOUT_DATE) ||
//       dateDisplay(moment().add(2, "days")),
//     party,
//     source_type: "ibe",
//     language: LocalStorage.get(KEYS.I18_NEXT_LNG) || DEFAULT_LANG,
//     currency: currencyData,
//     site_id: configData.site_id,
//     customer_id: null,
//     customer_type: "Customer",
//     agent_id: "",
//     code_used: "",
//     code_type: null,
//     corporatecompanyid: null,
//     customermembershiptypeid: null,
//     user_share_client_id: "",
//     user_country_id: 0,
//     device_type: getDeviceType(),
//     device_os: "", //window.userAgentData.platform,
//     allow_average_price: 1,
//     previous_day_threshold_time: "",
//     save_search: 0,
//     deals_packages: false,
//     senior_rate: false,
//     allow_tripadvisor: 0,
//     last_res: true,
//     directRoomCodes: null,
//     directRoomId: null,
//     membership_type: "",
//     is_corporate_enabled: 0,
//     special_offer: false,
//     flexi_check: false,
//     ignore_same_day: 1,
//     allow_same_day: 0,
//     customer_data: [],
//     show_public_rates: null,
//     allow_hold_reservation: 0,
//     club_enabled: false,
//     loyalty_club: {},
//     hotel_id: SessionStorage.get(KEYS.HOTEL_ID),
//     source_page,
//     http_user_agent: window.userAgent,
//     user_ip: 0,
//     ip: 0,
//     user_session_id: "",
//     mobile_device: "", //window.userAgentData.mobile,
//     user_browser: window.userAgent,
//     user_os: window.userAgent,

//     group_reservation_id: 217725063,
//     reservation_ids: [217725063],
//     policy_time_format: "1",
//     dateformat: "d M Y",
//     site_name: "Claridges"
//   };
//   return data;
// };

export const getSaveReservationRequest = userdata => {

  let configData = LocalStorage.getJSON(KEYS.CONFIG) || [];
  if (configData?.site_id === undefined) {
    return false;
  }

  let lang = localStorage.getItem("i18nextLng")
    ? localStorage.getItem("i18nextLng")
    : "en";

  const startDate = moment(SessionStorage.get(KEYS.CHECK_IN_DATE));
  const endDate = moment(SessionStorage.get(KEYS.CHECKOUT_DATE));

  let roomstay = [];
  if (userdata.guest?.length) {
    roomstay = userdata.guest.map(item => {
      let addons = [];
      if (item.addonData?.length) {
        item.addonData.map(addonsItem => {
          if (addonsItem.count > 0) {
            for (
              var m = moment(startDate);
              m.isBefore(endDate);
              m.add(1, "days")
            ) {
              if (addons?.find(x => x.addon_id == addonsItem?.id && addonsItem?.addon_type == "one_off")) {
              } else {
                addons.push({
                  addon_id: addonsItem.id,
                  room_id: item.room_info.room_id,
                  rate_id: item.room_info.rate_id,
                  addon_quantity: addonsItem.count,
                  adult_count: item.adults,
                  child_count: item.childrens.length,
                  addon_date: m.format("YYYY-MM-DD"),
                  addon_time: ""
                });
              }
            }
          }
        });
      }

      return {
        room_count: item?.childrens?.length ? item.childrens.length : 0,
        room_id: item.room_info.room_id,
        rate_id: item.room_info.rate_id,
        guest_adult_count: item.adults,
        guest_child_count: item.childrens.length,
        adults: item.adults,
        children: item.childrens.length,
        children_ages: [],
        infants: 0,
        infant_ages: [],
        addons,
        guestdetails: [
          {
            // title: item?.prefix || PREFIX[0].value,
            title: item?.prefix || PREFIX[lang][0],
            first_name: item.firstName,
            last_name: item.lastName,
            email: "",
            check_in_time_hour: null,
            check_in_time_minute: null,
            check_out_time_hour: null,
            check_out_time_minute: null,
            type: "adult",
            iamstay: 1,
            age: 0,

          }
        ],
        arrival_time: "",
        departure_time: "",
        gurantee_codes: "",
        room_pay_now: "",
        user_selected_room_count: 1,
        comment: userdata?.additional || item.additional || ""
      };
    });
  }

  let expirydate = userdata.expiryDate.split("/");

  let data = {
    start_date:
      SessionStorage.get(KEYS.CHECK_IN_DATE) ||
      dateDisplay(moment().add(1, "days")),
    end_date:
      SessionStorage.get(KEYS.CHECKOUT_DATE) ||
      dateDisplay(moment().add(2, "days")),
    hotel_id: SessionStorage.get(KEYS.HOTEL_ID),
    res_action: "new",
    reference_id: "",
    reservation_source: "js_ibe",
    access_source: "pc",
    site_name: configData.site_title,
    site_id: configData.site_id,
    user_ip: "",
    user_currency: 1,
    hotel_currency: "GBP",
    policy_time_format: configData.policy_time_format,
    dateformat: configData.dateformat,
    company_id: null,
    flexi_check: false,
    allow_day_restriction: true,
    allow_rateplan_restriction: false,
    user_language: LocalStorage.get(KEYS.I18_NEXT_LNG) ? LocalStorage.get(KEYS.I18_NEXT_LNG) : 'en',
    welcome_mail_ibe: 0,
    site_url: configData.site_url,
    ibe_base_url: window.location.origin,
    company_name: null,
    substitute_room: false,
    isMobile: false,
    update_profile_address: 0,
    maxCancellationTime: null,
    samedays: 0,
    sameday_override_policy: 0,
    allow_card_same_day_booking: 0,
    customer: {
      title: userdata.prefix,
      first_name: userdata.firstName,
      last_name: userdata.lastName,
      phone: userdata.phoneNumber,
      email: userdata.email,
      confirm_email: userdata.confirmEmail,
      password: "",
      confirm_password: "",
      country: userdata.country,
      address: {
        address1: userdata.addressLine1,
        city: userdata.city || "",
        state: userdata.addressLine2 || "",
        postal_code: userdata.zipCode,
        country: userdata.country,
        address2: ""
      }
    },
    code_type: SessionStorage.get(KEYS.BOOKING_CODE) ? 2 : "",
    code_used: SessionStorage.get(KEYS.BOOKING_CODE)
      ? SessionStorage.get(KEYS.BOOKING_CODE)
      : "",
    customer_type: 1,
    membership_type: "",
    is_membership: 0,
    bclubmember: "",
    iatano: userdata.iatano,
    travel_agent_id: 0,
    customer_id: null,
    is_guest: 1,
    flight_arrival: "",
    flight_origin: "",
    flight_number: "",
    arrival_time: "",
    departure_time: "",
    loyalty_club: "",
    restrict_amend_for_pointbooking: null,
    restrict_cancel_for_pointbooking: null,
    newsletter: userdata.optIn,
    customBE: "",
    trackingId: "",
    user_browser: window.userAgent,
    user_os: window.userAgent,
    is_hold: 0,
    hold_without_pay: 0,
    hearus: "",
    payment_gateway: null,
    payment_gateway_pay_now: null,
    payment_gateway_pay_later: null,
    payment_gateway_modification_charge: null,
    xpm_hold: null,
    special_requests: "",
    invoice_instructions: "",
    comment: userdata.additional,
    purpose_of_stay: "",
    colleague_id: null,
    colleague_name: "",
    smoking_room: 0,
    microsite_name: "",
    meta_source: "",
    save_cc: 0,
    booker_profile: "",
    payment_method: {
      card_type: 2,
      card_number: userdata.cardNumber,
      cardholder_name: userdata.nameOnCard,
      expiration_month: expirydate[0],
      expiration_year: "20" + expirydate[1],
      cvv: userdata.cvv,
      valid_month: "",
      valid_year: "",
      issue_no: "",
      billing_address: {
        address1: userdata.addressLine1,
        city: userdata.city || "",
        state: userdata.addressLine2 || "",
        postal_code: userdata.zipCode,
        country: userdata.country,
        address2: ""
      }
    },
    roomstay,
    campaign_id: 0,
    lead_id: 0,
    is_allow_childbucket: 0,
    tnc: "",
    is_corporate_enabled: 0,
    club_tier_join: 0,
    site_group: "",
    is_booking_engine: "",
    site_membership_type: "",
    loyalty_club_abbr: "",
    term_and_conditions: "",
    cookie_policy: "",
    privacy_policy: "",
    hotelier_client: "no",
    refund_amount: null,
    original_pay_now: null,
    external_program_id: null,
    external_membership_id: null,
    allow_payment_on_modification: 0,
    allow_payment_on_cancellation: 0,
    previous_reservation_paid_amount: null,
    use_points: 0,
    total_points_used: 0,
    totalAmountAfterPoint: 0,
    apply_promo_code: 0,
    client_id: configData.client_id
  };
  return data;
};

export const getModifyReservationRequest = userdata => {
  let configData = LocalStorage.getJSON(KEYS.CONFIG) || [];
  if (configData?.site_id === undefined) {
    return false;
  }
  let lang = localStorage.getItem("i18nextLng")
    ? localStorage.getItem("i18nextLng")
    : "en";
  const startDate = moment(SessionStorage.get(KEYS.CHECK_IN_DATE));
  const endDate = moment(SessionStorage.get(KEYS.CHECKOUT_DATE));

  let roomstay = [];
  if (userdata.guest?.length) {
    roomstay = userdata.guest.map(item => {
      let addons = [];
      if (item.addonData?.length) {
        item.addonData.map(addonsItem => {
          if (addonsItem.count > 0) {
            for (
              var m = moment(startDate);
              m.isBefore(endDate);
              m.add(1, "days")
            ) {
              addons.push({
                addon_id: addonsItem.id,
                room_id: item.room_info.room_id,
                rate_id: item.room_info.rate_id,
                addon_quantity: addonsItem.count,
                adult_count: item.adults,
                child_count: item.childrens.length,
                addon_date: m.format("YYYY-MM-DD"),
                addon_time: ""
              });
            }
          }
        });
      }

      return {
        room_count: item?.childrens?.length ? item.childrens.length : 0,
        room_id: item.room_info.room_id,
        rate_id: item.room_info.rate_id,
        guest_adult_count: item.adults,
        guest_child_count: item.childrens.length,
        adults: item.adults,
        children: item.childrens.length,
        children_ages: [],
        infants: 0,
        infant_ages: [],
        addons,
        guestdetails: [
          {
            // title: item?.prefix || PREFIX[0].value,
            title: item?.prefix || PREFIX[lang][0],
            first_name: item.firstName,
            last_name: item.lastName,
            email: "",
            check_in_time_hour: null,
            check_in_time_minute: null,
            check_out_time_hour: null,
            check_out_time_minute: null,
            type: "adult",
            iamstay: item.stayInRoom ? 1 : 0,
            age: 0
          }
        ],
        arrival_time: "",
        departure_time: "",
        gurantee_codes: "",
        room_pay_now: "",
        user_selected_room_count: 1,
        comment: userdata?.additional || item.additional || ""
      };
    });
  }

  let data = {
    start_date: SessionStorage.get(KEYS.CHECK_IN_DATE) || dateDisplay(moment().add(1, "days")),
    end_date: SessionStorage.get(KEYS.CHECKOUT_DATE) || dateDisplay(moment().add(2, "days")),
    hotel_id: SessionStorage.get(KEYS.HOTEL_ID),
    res_action: "modify",
    reference_id: "",
    reservation_source: "js_ibe",
    access_source: "pc",
    site_name: configData.site_title,
    site_id: configData.site_id,
    user_ip: "",
    user_currency: 1, //get currency id from selected currency
    hotel_currency: LocalStorage.get(KEYS.SELECTED_CURRENCY), // checkout abbrevation
    policy_time_format: configData.policy_time_format,
    dateformat: configData.dateformat,
    company_id: null,
    flexi_check: false,
    allow_day_restriction: true,
    allow_rateplan_restriction: false,
    user_language: LocalStorage.get(KEYS.I18_NEXT_LNG),
    welcome_mail_ibe: 0,
    site_url: configData.site_url,
    ibe_base_url: window.location.origin,
    company_name: null,
    substitute_room: false,
    isMobile: false,
    update_profile_address: 'on',
    maxCancellationTime: null,
    samedays: 0,
    sameday_override_policy: 0,
    allow_card_same_day_booking: 0,
    customer: {
      title: userdata.prefix,
      first_name: userdata.firstName,
      last_name: userdata.lastName,
      phone: userdata.phoneNumber,
      email: userdata.email,
      confirm_email: userdata.confirmEmail,
      password: "",
      confirm_password: "",
      country: userdata.country,
      address: {
        address1: userdata.addressLine1,
        city: userdata.city || "",
        state: userdata.addressLine2 || "",
        postal_code: userdata.zipCode,
        country: userdata.country,
        address2: userdata.addressLine2 || ""
      }
    },
    code_type: SessionStorage.get(KEYS.BOOKING_CODE) ? 2 : "",
    code_used: SessionStorage.get(KEYS.BOOKING_CODE)
      ? SessionStorage.get(KEYS.BOOKING_CODE)
      : "",
    customer_type: 1,
    membership_type: "",
    is_membership: 0,
    bclubmember: "",
    iatano: userdata?.iatano || "",
    travel_agent_id: 0,
    is_guest: 1,
    flight_arrival: "",
    flight_origin: "",
    flight_number: "",
    arrival_time: "",
    departure_time: "",
    loyalty_club: "",
    restrict_amend_for_pointbooking: null,
    restrict_cancel_for_pointbooking: null,
    newsletter: userdata.optIn,
    customBE: "",
    trackingId: "",
    user_browser: window.userAgent,
    user_os: window.userAgent,
    is_hold: 0,
    hold_without_pay: 0,
    hearus: "",
    payment_gateway: null,
    payment_gateway_pay_now: null,
    payment_gateway_pay_later: null,
    payment_gateway_modification_charge: null,
    xpm_hold: null,
    special_requests: "",
    invoice_instructions: "",
    comment: "",
    purpose_of_stay: "",
    colleague_id: null,
    colleague_name: "",
    smoking_room: 0,
    microsite_name: "",
    meta_source: "",
    save_cc: 0,
    booker_profile: "",
    payment_method: {
      card_number: userdata.cardNumber,
      cardholder_name: userdata.nameOnCard,
      expiration_month: userdata.expiryDate.slice(0, 2),
      expiration_year: `20${userdata.expiryDate.slice(3)}`,
      cvv: userdata.cvv,
      valid_month: "",
      valid_year: "",
      issue_no: "",
      billing_address: {
        address1: userdata.addressLine1,
        city: userdata.city || "",
        state: userdata.addressLine2 || "",
        postal_code: userdata.zipCode,
        country: userdata.country,
        address2: userdata.addressLine2 || ""
      }
    },
    roomstay,
    campaign_id: 0,
    lead_id: 0,
    is_allow_childbucket: 0,
    tnc: "",
    is_corporate_enabled: 0,
    club_tier_join: 0,
    site_group: "",
    is_booking_engine: "",
    site_membership_type: "",
    loyalty_club_abbr: "",
    term_and_conditions: "",
    cookie_policy: "",
    privacy_policy: "",
    hotelier_client: "no",
    refund_amount: null,
    original_pay_now: null,
    external_program_id: null,
    external_membership_id: null,
    allow_payment_on_modification: 0,
    allow_payment_on_cancellation: 0,
    previous_reservation_paid_amount: null,
    use_points: 0,
    total_points_used: 0,
    totalAmountAfterPoint: 0,
    apply_promo_code: 0,
    reservation_id: SessionStorage.getJSON(KEYS.IS_RESERVATION)?.customer_res_id,
    group_reservation_id: SessionStorage.getJSON(KEYS.IS_RESERVATION)?.group_reservation_id,
    client_id: configData.client_id
  };
  return data;
};

export const getReservationRequest = () => {
  let configData = LocalStorage.getJSON(KEYS.CONFIG) || [];
  if (configData?.site_id === undefined) {
    return false;
  }

  let data = {
    site_id: configData.site_id,
    language: LocalStorage.get(KEYS.I18_NEXT_LNG) || DEFAULT_LANG,
    site_name: configData.site_title,
    policy_time_format: configData.policy_time_format,
    dateformat: configData.dateformat,
    call_from: "express_booking",
    client_id: configData.client_id
  };
  return data;
};

export const getCancelReservationRequest = ({
  // currencyData = DEFAULT_CURRENCY,
  // source_page,
  bookingData,
}) => {
  // return bookingData;

  let configData = LocalStorage.getJSON(KEYS.CONFIG);
  if (configData?.site_id === undefined) {
    return false;
  }
  let data = {
    reservation_id: bookingData?.customer_res_id,
    site_id: bookingData?.site_id,
    customer_id: bookingData?.customer_id,
    channel_id: bookingData?.channel_id,
    // source_page: source_page,
    reservation_type: "single",
    site_name: configData.site_title,
    source: "ibe",
    meta_source: "",
    mobile_device: false,
    microsite: null,
    booking_code: "",
    customer_type: 1,
    customer_membership_name: "",
    language: LocalStorage.get(KEYS.I18_NEXT_LNG) || DEFAULT_LANG,
    policy_time_format: "1",
    dateformat: "d M Y",
    hotel_id: SessionStorage.get(KEYS.HOTEL_ID),
    hotelier_client: "no",
    cancellation_reason: "Manual",
    user_ip: 0,
    ip: 0,
    user_session_id: "",
    // http_user_agent:
    //   "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/101.0.0.0Safari/537.36",
    // user_browser:
    //   "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/101.0.0.0Safari/537.36",
    // user_os:
    //   "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/101.0.0.0Safari/537.36",
    http_user_agent: window.userAgent,
    user_browser: window.userAgent,
    user_os: window.userAgent,
    client_id: configData.client_id
  };
  return data;
};

export const getCommonRequest = () => {
  let data = {
    ip: "",
    user_ip: "",
    user_session_id: "",
    http_user_agent: "",
    mobile_device: "",
    user_browser: "",
    user_os: "",
    request_source: "IBE",
    referer_page: "",
    ip_country: "",
    associated_ibe: null
  }
  return data;
}

export const getSendEmailRequest = ({ siteConfig, confirmationData }) => {
  // send confirmation email
  let reservation_ids_arr = [];

  // Get all reservation ids and store them in a array
  reservation_ids_arr = confirmationData.room_stay.map((item) => {
    return item.customer_res_id;
  });

  let livesite = 0;
  if (window.location.href.indexOf("bookings") != -1) {
    livesite = 1;
  }

  let data = {
    group_reservation_id: confirmationData.group_reservation_id,
    reservation_ids: reservation_ids_arr,
    site_id: siteConfig.site_id,
    language: confirmationData.booking_lang,
    policy_time_format: siteConfig.policy_time_format,
    dateformat: siteConfig.dateformat,
    site_name: siteConfig.site_title,
    toemail: confirmationData.email,
    mailLogo: siteConfig.mailLogo,
    faxLogo: siteConfig.faxLogo,
    site_url: (livesite === 1) ? siteConfig.site_live_url : siteConfig.site_test_url,
    modify_res: false,
    client_id: siteConfig.client_id
  };

  return data;
};

export const getMetaTagsRequest = ({ currentPage }) => {
  let configData = LocalStorage.getJSON(KEYS.CONFIG);
  if (configData?.site_id === undefined) {
    return false;
  }

  let lauguageParam = LocalStorage.get(KEYS.I18_NEXT_LNG) == "false" ? "en" : LocalStorage.get(KEYS.I18_NEXT_LNG);

  let data = {
    language: lauguageParam || DEFAULT_LANG,
    hotel_id: SessionStorage.get(KEYS.HOTEL_ID),
    site_id: configData.site_id,
    pagename: currentPage,
  };
  return data;
};

export const getNoAvailabilityRequest = () => {
  let configData = LocalStorage.getJSON(KEYS.CONFIG);
  if (configData?.site_id === undefined) {
    return false;
  }

  let party = [];
  let partyData = SessionStorage.getJSON(KEYS.ROOM_DATA);
  if (partyData?.length) {
    party = partyData.map(item => {
      return {
        child: item?.childrens?.length ? item.childrens.length : 0,
        adults: item.adults
      };
    });
  }
  let data = {
    start_date: SessionStorage.get(KEYS.CHECK_IN_DATE) || dateDisplay(moment().add(1, "days")),
    end_date: SessionStorage.get(KEYS.CHECKOUT_DATE) || dateDisplay(moment().add(2, "days")),
    source_type: "ibe",
    language: LocalStorage.get(KEYS.I18_NEXT_LNG),
    currency: LocalStorage.get(KEYS.SELECTED_CURRENCY),
    site_id: configData?.site_id || "",
    customer_id: null,
    customer_type: null,
    agent_id: "",
    code_used: "",
    code_type: null,
    seibu_login: null,
    corporatecompanyid: null,
    customermembershiptypeid: null,
    user_share_client_id: "",
    user_country_id: null,
    device_type: "desktop",
    device_os: null,
    allow_average_price: 0,
    previous_day_threshold_time: configData?.previous_day_threshold_time || "",
    save_search: 0,
    deals_packages: false,
    senior_rate: false,
    flexi_check: false,
    allow_tripadvisor: 0,
    last_res: true,
    directRoomCodes: null,
    directRoomId: null,
    membership_type: "",
    city_id: "",
    is_corporate_enabled: 1,
    special_offer: false,
    allow_region: 0,
    show_public_rates: null,
    region_data: null,
    customer_data: [],
    allow_hold_reservation: 0,
    ignore_same_day: 1,
    allow_same_day: 1,
    club_enabled: false,
    loyalty_club: [],
    show_random_list: 0,
    allow_partial_rate_display: 0,
    party,
  }
  return data;
}

/**
 * Prepare parameters for Reservation Cancellation Email
 * @param {*} param0 
 * @returns 
 */
export const getReservationCancellationEmailRequest = ({
  siteConfig,
  bookingData,
  cancelationData,
}) => {
  let livesite = 0;
  if (window.location.href.indexOf("bookings") != -1) {
    livesite = 1;
  }
  let data = {
    reservation_id: bookingData.customer_res_id.toString(),
    site_id: siteConfig.site_id,
    language:
      LocalStorage.get(KEYS.I18_NEXT_LNG) === "false"
        ? "en"
        : LocalStorage.get(KEYS.I18_NEXT_LNG),
    site_name: siteConfig.site_title,
    policy_time_format: siteConfig.policy_time_format,
    dateformat: siteConfig.dateformat,
    mailLogo: siteConfig.mailLogo,
    faxLogo: siteConfig.faxLogo,
    site_url:
      livesite === 1
        ? siteConfig.site_live_url
        : siteConfig.site_test_url,
    cancellation_data: cancelationData,
    client_id: siteConfig.client_id,
  };
  return data;
};

/**
 * Prepare parameters for site maintenance api request
 * @param {*} param
 * @returns 
 */
export const getSiteMaintenanceRequest = ({ siteId, hotelId, lang }) => {
  let data = {
    site_id: siteId,
    hotel_id: hotelId,
    language: lang,
  };
  return data;
};

/**
 * Prepare parameters for 'message closed user group' api request
 * @param {*} param
 * @returns 
 */
export const getMessageClosedUserGroupRequest = ({ siteId, language }) => {
  let data = {
    "site_id": siteId,
    language
  };
  return data;
};