import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { TRANSLATIONS_EN } from "../locales/language/en";
import { TRANSLATIONS_FR } from "../locales/language/fr";
import { TRANSLATIONS_EN_10203099 } from '../locales/language/en-SITE_ID-10203099'
import { TRANSLATIONS_EN_10203058 } from '../locales/language/en-SITE_ID-10203058'
import { TRANSLATIONS_EN_10203059 } from '../locales/language/en-SITE_ID-10203059'

let enObject = TRANSLATIONS_EN;
let frObject = TRANSLATIONS_FR;


// load translation file based on site id
// let config = localStorage.getItem("config") ? JSON.parse(localStorage.getItem("config")) : [];
// let siteId = config?.site_id ? config?.site_id : [];

if (window.location.hostname.includes('the-emory') || window.location.hostname.includes('theemory')) {
	enObject = TRANSLATIONS_EN_10203099; // assign new translation file based on site id
}
if (window.location.hostname.includes('maybournebeverlyhills') || window.location.hostname.includes('mbh')) {
	enObject = { ...enObject, ...TRANSLATIONS_EN_10203058 }; // assign new translation file based on site id
}

if (window.location.hostname.includes('maybourneriviera') || window.location.hostname.includes('mbr')) {
	enObject = { ...enObject, ...TRANSLATIONS_EN_10203059 }; // assign new translation file based on site id
}
// load translation file based on site id

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources: {
			en: {
				translation: enObject,
			},
			fr: {
				translation: frObject,
			},
		},
	});

const url = new URL(window.location.href);
let lang = window.location.pathname.includes('//fr') ? "fr" : (url?.searchParams?.get('lang') || localStorage.getItem("lang") || "en");
i18n.changeLanguage(lang);
