import moment from "moment";
import { route } from "../utils/route";
import { SessionStorage } from "./storage";
import { imageUrl, KEYS, SITE_ID_LIST } from "../utils/constant";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { t } from "i18next";

export const dateDisplay = (date) => {
	return moment(date).format("YYYY-MM-DD");
};

export const getDeviceType = () => {
	const ua = navigator.userAgent;
	if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
		return "tablet";
	}
	if (
		/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
			ua
		)
	) {
		return "mobile";
	}
	return "desktop";
};

export function roomDescriptionDisplay(keyHighlight) {
	let beddingDetail = "";
	let beddingArray = keyHighlight?.bedding_attr ? Object.fromEntries(
		Object.entries(keyHighlight?.bedding_attr).filter(([_, value]) => value > 0)
	) : "";
	if (beddingArray) {
		beddingDetail = "";
		Object.keys(beddingArray).map(function (key) {
			let bednum = parseInt(beddingArray[key])
			if (bednum > 0) {
				let appendplural = '';
				if (bednum > 1) {
					appendplural = 's';
				}
				beddingDetail = `${beddingDetail}${beddingArray[key]} ${t(key)}${appendplural}, `;
			}
		});
		beddingDetail = `${beddingDetail.slice(0, -2)} ${keyHighlight?.bedding_attr?.additional_text ? keyHighlight?.bedding_attr?.additional_text : ""}`
	}

	let occupancyDetail = "";
	let occupancyArray = keyHighlight?.occupancy_attr ? Object.fromEntries(
		Object.entries(keyHighlight?.occupancy_attr).filter(([_, value]) => value > 0)
	) : "";
	if (beddingArray) {
		occupancyDetail = "";
		Object.keys(occupancyArray).map(function (key) {
			if (key == "sleeps_up_to") {
				occupancyDetail = `${occupancyDetail}${t("sleeps_upto")} ${occupancyArray[key]} ${t("people")}, `;
			} else {
				occupancyDetail = `${occupancyDetail}${occupancyArray[key]
					} ${key.replace(/_/g, " ").replace(/\b\w/g, function (match) {
						return match.toUpperCase();
					})}, `;
			}
		});
		occupancyDetail = `${occupancyDetail.slice(0, -2)} ${keyHighlight?.occupancy_attr?.additional_text ? keyHighlight?.occupancy_attr?.additional_text : ""}`;
	}

	let viewDetail = "";
	let viewArray = keyHighlight.room_attr ? keyHighlight.room_attr : {};
	if (viewArray) {
		viewDetail = "";
		Object.keys(viewArray).forEach(function (key) {
			if (key === "room_view" && Array.isArray(viewArray?.[key])) {
				viewDetail = `${viewDetail}${key}: ${viewArray[key]?.join(', ')} `;
			}
		});
		viewDetail = viewDetail.slice(0, -1);
		// console.log('viewArray -> ' + JSON.stringify(viewArray));
		// console.log("viewDetail -> " + JSON.stringify(viewDetail));
	}

	let areaDetail = "";
	if (keyHighlight.room_size) {
		areaDetail = `${t("avg_size")}: ${keyHighlight.room_size} ${keyHighlight.room_size_type.toLowerCase()} ${keyHighlight?.room_size_additional_text && !keyHighlight.room_size_alt ? "(" + keyHighlight?.room_size_additional_text + ")" : ""}`;
		if (keyHighlight.room_size_alt) {
			areaDetail = areaDetail + ` / ${keyHighlight.room_size_alt} ${keyHighlight.room_size_type_alt} ${keyHighlight?.room_size_additional_text ? keyHighlight?.room_size_additional_text : ""}`;
		}
	}

	let freetext = "";
	if (keyHighlight.other_attr) {
		freetext = keyHighlight.other_attr;
	}

	let keyHighlightsData = [];
	if (beddingDetail) {
		keyHighlightsData.push(beddingDetail);
	} else {
		keyHighlightsData.push('');
	}
	if (occupancyDetail) {
		keyHighlightsData.push(occupancyDetail);
	} else {
		keyHighlightsData.push('');
	}
	if (viewDetail) {
		keyHighlightsData.push(viewDetail);
	} else {
		keyHighlightsData.push('');
	}
	if (areaDetail) {
		keyHighlightsData.push(areaDetail);
	} else {
		keyHighlightsData.push('');
	}

	if (freetext) {
		keyHighlightsData.push(freetext);
	} else {
		keyHighlightsData.push('');
	}
	return keyHighlightsData;
}

export const currencyDisplay = (value) => {
	let currFormat = "en-US";
	value = value === undefined ? 'GBP' : value;
	return value.toLocaleString(currFormat, {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};

export const dayDiff = () => {
	let checkIn = moment(SessionStorage.get(KEYS.CHECK_IN_DATE));
	let checkOut = moment(SessionStorage.get(KEYS.CHECKOUT_DATE));
	return checkOut.diff(checkIn, "days");
};

export const getCardType = (cardNumber) => {
	let cardTypes = [
		{
			name: "American Express",
			pattern: /^3[47]/,
			validLengths: [15],
		},
		{
			name: "Diners Club",
			pattern: /^3(?:0[0-5]|[68])/,
			validLengths: [14],
		},
		{
			name: "Discover",
			pattern: /^6(?:011|5[0-9]{2})/,
			validLengths: [16],
		},
		{
			name: "JCB",
			pattern: /^(?:2131|1800|35\d{3})/,
			validLengths: [16],
		},
		{
			name: "Mastercard",
			pattern: /^5[1-5]/,
			validLengths: [16],
		},
		{
			name: "Visa",
			pattern: /^4/,
			validLengths: [13, 16],
		},
	];

	let cardType = cardTypes.find(function (type) {
		return type.pattern.test(cardNumber);
	});

	if (!cardType) {
		return null;
	}

	let validLengths = cardType.validLengths;
	let isValidLength = validLengths.some(function (length) {
		return cardNumber.length === length;
	});

	if (!isValidLength) {
		return null;
	}

	let sum = 0;
	let shouldDouble = false;
	for (let i = cardNumber.length - 1; i >= 0; i--) {
		let digit = parseInt(cardNumber.charAt(i));
		if (shouldDouble) {
			digit *= 2;
			if (digit > 9) {
				digit -= 9;
			}
		}
		sum += digit;
		shouldDouble = !shouldDouble;
	}
	let isLuhnValid = sum % 10 === 0;
	if (!isLuhnValid) {
		return null;
	}
	return cardType.name;
};

export const alphabeticName = (value) => {
	return value.replace(/[^a-zA-Z0-9\s' -]/g, "");
};

export const capitalizeStr = (str) => {
	return str
		?.split(" ")
		.map(
			(word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
		)
		.join(" ");
};

export const checkImage = (url) => {
	const img = new Image();
	img.src = url;
	return img.complete && img.naturalHeight !== 0;
};

export const BOOKING_STATUS = {
	0: t('cancelled'),
	5: t('pending'),
	1: t('confirmed'),
	3: t('cancelled'),
};

/**
 * check is Tax Inclusive or not in cart's each room item
 * @param {*} rate
 * @returns string
 */
export const isTaxInclusiveInCartSingleItem = (rate) => {
	const roomRateTaxes = rate.tax;
	var isTaxInclusive = false;
	var taxInclusive = false;
	var taxExclusive = false;

	// Check each tax and mark it 'true' or 'false' accordingly based on value found in "tax_inclusive" var
	for (var key in roomRateTaxes) {
		if (roomRateTaxes.hasOwnProperty(key)) {
			if (roomRateTaxes[key]["tax_inclusive"] === "Yes") {
				taxInclusive = true;
			}
			if (roomRateTaxes[key]["tax_inclusive"] === "No") {
				taxExclusive = true;
			}
		}
	}

	if (taxExclusive === true) {
		// if 'taxExclusive' is true
		isTaxInclusive = "exclusive_of_vat";
	}
	if (taxInclusive === true && taxExclusive === false) {
		// if var 'taxInclusive' is true & 'taxExclusive' is false
		isTaxInclusive = "inclusive_of_vat_sm";
	}

	return isTaxInclusive;
};

export const setTaxInclusiveInSession = (rate) => {
	const roomRateTaxes = rate.tax;
	var isTaxInclusive = false;
	var taxInclusive = false;
	var taxExclusive = false;

	// Check each tax and mark it 'true' or 'false' accordingly based on value found in "tax_inclusive" var
	for (var key in roomRateTaxes) {
		if (roomRateTaxes.hasOwnProperty(key)) {
			// console.log(key);  // Print Keys
			// console.log(roomRateTaxes[key]["tax_inclusive"]);  // Print Values

			if (roomRateTaxes[key]["tax_inclusive"] === "Yes") {
				taxInclusive = true;
			}
			if (roomRateTaxes[key]["tax_inclusive"] === "No") {
				taxExclusive = true;
			}
		}
	}

	if (taxExclusive === true) {
		// if 'taxExclusive' is true
		isTaxInclusive = "no";
	}
	if (taxInclusive === true && taxExclusive === false) {
		// if var 'taxInclusive' is true & 'taxExclusive' is false
		isTaxInclusive = "yes";
	}

	return isTaxInclusive;
};

export const setTaxInclusiveInBookingData = (taxDetails) => {
	var taxInclusive = false;
	var taxExclusive = false;

	if (taxDetails.inclusive) {
		taxDetails.inclusive.map((item) => {
			if (item.IsInclusive === 1) {
				taxInclusive = true;
			}
			if (item.IsInclusive === 0) {
				taxExclusive = true;
			}
			return "";
		});

		if (taxExclusive === true) {
			// if 'taxExclusive' is true
			return "exclusive_of_vat";
		}
		if (taxInclusive === true && taxExclusive === false) {
			// if var 'taxInclusive' is true & 'taxExclusive' is false
			return "inclusive_of_vat";
		}
	}

	return '';
};

// export const setRoomWiseTaxInclusive = (taxDetails) => {
//   var taxInclusive = false;
//   var taxExclusive = false;

//   taxDetails.inclusive.map((item) => {
//     if (item.IsInclusive === 1) {
//       taxInclusive = true;
//     }
//     if (item.IsInclusive === 0) {
//       taxExclusive = true;
//     }
//     return "";
//   });

//   if (taxExclusive === true) {
//     // if 'taxExclusive' is true
//     return "exclusive_of_vat";
//   }
//   if (taxInclusive === true && taxExclusive === false) {
//     // if var 'taxInclusive' is true & 'taxExclusive' is false
//     return "inclusive_of_vat";
//   }

//   return false;
// };

/**
 * Convert 24 hours time string into 12 hour time along with "Meridiem" (am,pm)
 * @param {string} time
 * @returns array
 * ref - https://stackoverflow.com/questions/13898423/ javascript-convert-24-hour-time-of-day-string-to-12-hour-time-with-am-pm-and-no
 */
export const convertTime24HourTo12Hours = (time) => {
	// Check correct time format and split into components
	time = time
		.toString()
		.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
	if (time.length > 1) {
		// If time format correct
		time = time.slice(1); // Remove full string match value
		time[5] = +time[0] < 12 ? "am" : "pm"; // Set AM/PM
		time[0] = +time[0] % 12 || 12; // Adjust hours
		// remove ":" & "00" from time format
		time = time.filter(function (letter) {
			if (letter !== "00" && letter !== ":") {
				return letter;
			}
		});
	}
	return time; // return array of numeric time[0] & meridiem value[1]
	// return time.join(""); // return adjusted time or original string
};

/**
 * Convert 24 hours time string into 12 hour with minutes time along with "Meridiem" (am,pm)
 * @param {*} time 
 * @returns 
 */
export const ConvertTime24HourTo12HoursWithMinutes = (time) => {
	const { t } = useTranslation();

	// Check correct time format and split into components
	time = time
		.toString()
		.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
	if (time.length > 1) {
		// If time format correct
		time = time.slice(1); // Remove full string match value
		// Set AM/PM/Mid Day
		if (time[0] < 12) {
			time[5] = " " + t("am_check_inout_time");
		} else if (time[0] > 12) {
			time[5] = " " + t("pm_check_inout_time");
		} else {
			time[5] = " " + t("mid_day_check_inout_time");
		}

		time[0] = +time[0] % 12 || 12; // Adjust hours
		time[0] = +time[0] < 10 ? '0' + time[0] : time[0]; // Append '0' if smaller than 0 (ex - 03)
	}
	return time.join(""); // return adjusted time or original string
};

// define where header/footer needs to shown
export const showHeaderFooter = (currentPath) => {
	if (
		// location.pathname === route.maintenance ||
		// currentPath === route.maintenance ||
		currentPath === route.fourZeroFour
	) {
		return false;
	} else {
		return true;
	}
};

/**
 * Get Newsletter's form action URL based on site id
 * @param {*} siteId
 * @returns string
 */
export const actionUrlBasedOnSiteId = (siteId) => {
	let lang = localStorage.getItem("lang")
		? localStorage.getItem("lang")
		: "en";

	let url_suffix = "newsletter-sign-up/";

	// if language is 'fr' & siteId matched 
	if (lang === "fr" && (siteId === 10203059 || siteId === 55597)) {
		url_suffix = "fr/newsletter-inscription/";
	}

	const siteAction = {
		[SITE_ID_LIST[0]]: `https://www.claridges.co.uk/${url_suffix}`,
		[SITE_ID_LIST[1]]: `https://www.the-berkeley.co.uk/${url_suffix}`,
		[SITE_ID_LIST[2]]: `https://www.the-connaught.co.uk/${url_suffix}`,
		[SITE_ID_LIST[3]]: `https://www.maybournebeverlyhills.com/${url_suffix}`,
		[SITE_ID_LIST[4]]: `https://www.maybourneriviera.com/${url_suffix}`,
		[SITE_ID_LIST[5]]: `https://www.the-emory.co.uk/${url_suffix}`,
		[SITE_ID_LIST[6]]: `https://www.maybourneriviera.com/${url_suffix}`,
		[SITE_ID_LIST[7]]: `https://www.maybournebeverlyhills.com/${url_suffix}`,
		[SITE_ID_LIST[8]]: `https://www.the-emory.co.uk/${url_suffix}`,
	};

	return siteAction[siteId];
};

/**
 * Get 360 Floor Plan url
 * @param {*} fileName
 * @returns string
 */
export const floorPlanUrl = (roomDetails) => {
	if (roomDetails?.floor_plan[0]?.url_path) {
		const floorPlanFileName = roomDetails?.floor_plan[0]?.url_path; // get single room floor plan file name
		const updatedImageUrl = imageUrl?.slice(0, -7); // remove '/images' from end of the url
		const hotelId = SessionStorage.get(KEYS.HOTEL_ID);

		return `${updatedImageUrl}/UploadFloorPlan/${hotelId}/${floorPlanFileName}`;
	} else {
		return "";
	}
};

export const childAgeWithYears = (item) => {
	let childAgeDisplayArray = [];
	let childArray = item.child_age.split("x");
	item.child > 0 &&
		childArray.length > 0 &&
		childArray.map((age, ageindex) => {
			if (age <= 1) {
				// return childAgeDisplayArray[ageindex] = age + " " + t("year");
				return (childAgeDisplayArray[ageindex] = age);
			} else {
				// return childAgeDisplayArray[ageindex] = age + " " + t("years");
				return (childAgeDisplayArray[ageindex] = age);
			}
		});
};

/**
 * Get site contact details based on site id
 */
export const getSiteContactDetails = (site_id) => {
	const siteContactDetailsObj = {
		[SITE_ID_LIST[0]]: {
			// claridges
			email: "claridgesreservations@claridges.co.uk",
			contactNo: "+44 (0)20 7107 8862",
			favicon: require("../styles/images/claridges-favicon.png"),
		},
		[SITE_ID_LIST[1]]: {
			// The Berkeley
			email: "reservations@the-berkeley.co.uk",
			contactNo: "+44 (0)20 7107 8927",
			favicon: require("../styles/images/berkeley-favicon.png"),
		},
		[SITE_ID_LIST[2]]: {
			// The Connaught
			email: "reservations@the-connaught.co.uk",
			contactNo: "+44 (0)20 7107 8945",
			favicon: require("../styles/images/connaught-favicon.png"),
		},
		[SITE_ID_LIST[3]]: {
			// The Maybourne Beverly Hills
			email: "reservations@maybournebeverlyhills.com",
			contactNo: "+1 (310) 860 7940",
			favicon: require("../styles/images/maybourne-beverly-hills-favicon.png"),
		},
		[SITE_ID_LIST[4]]: {
			// The Maybourne Riviera
			email: "reservations@maybourneriviera.com",
			contactNo: "+33 4 93 37 22 41",
			favicon: require("../styles/images/riviera-favicon.png"),
		},
		[SITE_ID_LIST[5]]: {
			// the-emory
			email: "reservations@the-emory.co.uk",
			contactNo: "+44 207 862 5200",
			favicon: require("../styles/images/emory-favicon.png"),
		},
		[SITE_ID_LIST[6]]: {
			// The Maybourne Riviera
			email: "reservations@maybourneriviera.com",
			contactNo: "+33 4 93 37 22 41",
			favicon: require("../styles/images/riviera-favicon.png"),
		},
		[SITE_ID_LIST[7]]: {
			// The Maybourne Beverly Hills
			email: "reservations@maybournebeverlyhills.com",
			contactNo: "+1 (310) 860 7940",
			favicon: require("../styles/images/maybourne-beverly-hills-favicon.png"),
		},
		[SITE_ID_LIST[8]]: {
			// the-emory
			email: "reservations@the-emory.co.uk",
			contactNo: "+44 207 862 5200",
			favicon: require("../styles/images/emory-favicon.png"),
		},
	};

	for (const prop in siteContactDetailsObj) {
		let siteId = site_id.toString();

		if (prop === siteId) {
			return siteContactDetailsObj[prop];
		}
	}
};

export const addSpaceAfterComma = (value) => {
	return value?.replace(/,/g, ", ")
}

/**
 * Change suggested currency
 */
export const getSuggestedCurrencyBasedOnSiteId = (
	siteId,
	currencyArray,
) => {
	let currencyUpdatedOrder = [];
	let updatedCurrencyArray = [];
	let updatedSuggestedCurrency = [];

	if (
		siteId === null ||
		siteId === '' ||
		siteId === SITE_ID_LIST[0] ||
		siteId === SITE_ID_LIST[1] ||
		siteId === SITE_ID_LIST[2] ||
		siteId === SITE_ID_LIST[5] ||
		siteId === SITE_ID_LIST[8]
	) {
		// Claridges, The Berkley, The Connaught, The Emory
		currencyUpdatedOrder = [
			{ abbrevation: "GBP", ordering: 1 },
			{ abbrevation: "USD", ordering: 2 },
			{ abbrevation: "EUR", ordering: 3 },
			{ abbrevation: "CAD", ordering: 4 },
			{ abbrevation: "AED", ordering: 5 },
			{ abbrevation: "AUD", ordering: 6 },
			{ abbrevation: "JPY", ordering: 7 },
			{ abbrevation: "CNY", ordering: 8 },
		];
	}

	if (siteId === SITE_ID_LIST[4] || siteId === SITE_ID_LIST[6]) {
		// The Maybourne Riviera
		currencyUpdatedOrder = [
			{ abbrevation: "EUR", ordering: 1 },
			{ abbrevation: "GBP", ordering: 2 },
			{ abbrevation: "USD", ordering: 3 },
			{ abbrevation: "CAD", ordering: 4 },
			{ abbrevation: "AED", ordering: 5 },
			{ abbrevation: "AUD", ordering: 6 },
			{ abbrevation: "JPY", ordering: 7 },
			{ abbrevation: "CNY", ordering: 8 },
		];
	}

	if (siteId === SITE_ID_LIST[3] || siteId === SITE_ID_LIST[7]) {
		// The Maybourne Beverly Hills
		currencyUpdatedOrder = [
			{ abbrevation: "USD", ordering: 1 },
			{ abbrevation: "GBP", ordering: 2 },
			{ abbrevation: "EUR", ordering: 3 },
			{ abbrevation: "CAD", ordering: 4 },
			{ abbrevation: "AED", ordering: 5 },
			{ abbrevation: "AUD", ordering: 6 },
			{ abbrevation: "JPY", ordering: 7 },
			{ abbrevation: "CNY", ordering: 8 },
		];
	}

	// check both array's object abbrevation, where matched update the ordering of the currency and where not matched make the 'ordering' to 0
	updatedCurrencyArray = currencyArray.map(
		(element) => (
			(element.ordering =
				currencyUpdatedOrder.find(
					(a) => a.abbrevation == element.abbrevation
				)?.ordering || 0),
			element
		)
	);

	// return elements where 'ordering' is not equal to 0
	updatedSuggestedCurrency = updatedCurrencyArray.filter(
		(item) => item.ordering !== 0
	);

	// sort the array as per the 'ordering' key
	updatedSuggestedCurrency = updatedSuggestedCurrency.sort(
		(a, b) => a.ordering - b.ordering
	);

	return updatedSuggestedCurrency;
};

export const privacyPolicyLink = (siteId, language) => {
	let privacyPolicyLinks = {};
	if (language === "fr") {
		privacyPolicyLinks = {
			[SITE_ID_LIST[0]]: "https://www.claridges.co.uk/privacy-policy/",
			[SITE_ID_LIST[1]]: "https://www.the-berkeley.co.uk/privacy-policy/",
			[SITE_ID_LIST[2]]:
				"https://www.the-connaught.co.uk/privacy-policy/",
			[SITE_ID_LIST[3]]:
				"https://www.maybournebeverlyhills.com/privacy-policy/",
			[SITE_ID_LIST[4]]:
				"https://www.maybourneriviera.com/fr/politique-de-confidentialite/",
			[SITE_ID_LIST[5]]: "https://www.the-emory.co.uk/privacy-policy/",
			[SITE_ID_LIST[6]]:
				"https://www.maybourneriviera.com/fr/politique-de-confidentialite/",
			[SITE_ID_LIST[7]]:
				"https://www.maybournebeverlyhills.com/privacy-policy/",
			[SITE_ID_LIST[8]]: "https://www.the-emory.co.uk/privacy-policy/",
		};
	} else {
		privacyPolicyLinks = {
			[SITE_ID_LIST[0]]: "https://www.claridges.co.uk/privacy-policy/",
			[SITE_ID_LIST[1]]: "https://www.the-berkeley.co.uk/privacy-policy/",
			[SITE_ID_LIST[2]]:
				"https://www.the-connaught.co.uk/privacy-policy/",
			[SITE_ID_LIST[3]]:
				"https://www.maybournebeverlyhills.com/privacy-policy/",
			[SITE_ID_LIST[4]]:
				"https://www.maybourneriviera.com/privacy-policy/",
			[SITE_ID_LIST[5]]: "https://www.the-emory.co.uk/privacy-policy/",
			[SITE_ID_LIST[6]]:
				"https://www.maybourneriviera.com/privacy-policy/",
			[SITE_ID_LIST[7]]:
				"https://www.maybournebeverlyhills.com/privacy-policy/",
			[SITE_ID_LIST[8]]: "https://www.the-emory.co.uk/privacy-policy/",
		};
	}
	return privacyPolicyLinks[siteId];
};

export const disableScroll = () => {
	let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
	let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
	window.onscroll = function () {
		window.scrollTo(scrollLeft, scrollTop);
	};
}

export const enableScroll = () => {
	window.onscroll = function () { };
}

// Trap Tab focus on Cart & Header area only
export const trapTabFocusWithinSelection = () => {
	let trapFocusElement = document.getElementById("mainCart");
	var focusableEls = trapFocusElement.querySelectorAll(
		'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
	);

	var firstFocusableEl = focusableEls[0];
	var lastFocusableEl = focusableEls[focusableEls.length - 1];
	let siteLogo = document.getElementById("siteLogo");
	var KEYCODE_TAB = 9;

	trapFocusElement.addEventListener("keydown", function (e) {
		var isTabPressed = e.key === "Tab" || e.keyCode === KEYCODE_TAB;

		if (!isTabPressed) {
			return;
		}

		if (e.shiftKey) {
			/* shift + tab */
			if (document.activeElement === firstFocusableEl) {
				lastFocusableEl.focus();
				e.preventDefault();
			}
		} /* tab */
		// else {
		// 	if (document.activeElement === lastFocusableEl) {				
		// 		firstFocusableEl.focus();
		// 		// siteLogo.focus();
		// 		// e.preventDefault();
		// 	}
		// }
	});
};

// Recursive function to change tabindex from 0 to -1 for all elements and their descendants
export const changeTabindexToMinusOne = (element) => {
	if (
		!element.classList.contains("slick-slide")
	) {
		element.setAttribute("tabindex", "-1");
		element.classList.add("offFocus");
	}


	const childElements = element.children;
	for (let i = 0; i < childElements.length; i++) {
		changeTabindexToMinusOne(childElements[i]);
	}
}

// Recursive function to change tabindex from -1 to 0 for all elements and their descendants
export const changeTabindexMinusOneToZero = (element) => {
	const elementsWithTabindexMinusOne =
		element.querySelectorAll('[tabindex="-1"]');

	elementsWithTabindexMinusOne.forEach((element) => {
		if (
			// !element.classList.contains("slick-slide") &&
			(element.classList.contains("offFocus") &&
				element.classList.contains("customTitle")) ||
			(element.classList.contains("offFocus") &&
				element.classList.contains("viewRateBtn")) ||
			(element.classList.contains("offFocus") &&
				element.classList.contains("filtersLink")) ||
			(element.classList.contains("offFocus") &&
				element.classList.contains("compareRoomBtn")) ||
			(element.classList.contains("offFocus") &&
				element.classList.contains("customBtnTransparent"))
		) {
			element.setAttribute("tabindex", "0");
			element.classList.remove("offFocus");
			// element.removeAttribute("tabindex");
		}

		// remove tabindex for element id 'roomList'
		var roomListElement = document.getElementById("roomList");
		if (roomListElement) {
			roomListElement.removeAttribute("tabindex");
			element.classList.remove("offFocus");
		}

		// remove tabindex from class id 
		if (
			element.classList.contains("offFocus") ||
			element.classList.contains("pageTitle") ||
			element.classList.contains("filtersCompareBar") ||
			element.classList.contains("roomsList") ||
			element.classList.contains("roomListImages") ||
			element.classList.contains("singleRoomsList")
		) {
			element.removeAttribute("tabindex");
			// element.classList.remove("offFocus");
		}
	});




	// set tabindex = '-1' for Video tag
	var videoElements = element.querySelectorAll("video");
	videoElements.forEach(function (videoElement) {
		videoElement.setAttribute("tabindex", "-1");
	});



	var fullImgModalElements = element.querySelectorAll(".dBlock.fullImgModal");
	fullImgModalElements.forEach(function (fullImgEle, index) {
		if (index >= 1) {
			fullImgEle.setAttribute("tabindex", "0");
		}

		var parentElement = fullImgEle.parentElement;
		if (parentElement) {
			parentElement.setAttribute("tabindex", "-1");
		}
	});


	// innerImage;
	var innerImgElements = element.querySelectorAll(".innerImage");
	innerImgElements.forEach(function (innerImgEle) {
		innerImgEle.setAttribute("tabindex", "-1");
	});

}


// Trap Tab focus on Cart & Header area only
export const OnDetailPageTrapTabFocusWithinSelection = () => {
	let trapFocusElement = document.getElementById("mainCart");
	var focusableEls = trapFocusElement.querySelectorAll(
		'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
	);

	var firstFocusableEl = focusableEls[0];
	var lastFocusableEl = focusableEls[focusableEls.length - 1];
	let siteLogo = document.getElementById("siteLogo");
	var KEYCODE_TAB = 9;

	trapFocusElement.addEventListener("keydown", function (e) {
		var isTabPressed = e.key === "Tab" || e.keyCode === KEYCODE_TAB;

		if (!isTabPressed) {
			return;
		}

		if (e.shiftKey) {
			/* shift + tab */
			if (document.activeElement === firstFocusableEl) {
				lastFocusableEl.focus();
				e.preventDefault();
			}
		} /* tab */
		// else {
		// 	if (document.activeElement === lastFocusableEl) {
		// 		// firstFocusableEl.focus();
		// 		siteLogo.focus();
		// 		// e.preventDefault();
		// 	}
		// }
	});
};


// Recursive function to change tabindex from -1 to 0 for all elements and their descendants
export const OnDetailPageChangeTabindexMinusOneToZero = (element) => {
	const elementsWithTabindexMinusOne =
		element.querySelectorAll('[tabindex="-1"]');

	elementsWithTabindexMinusOne.forEach((element) => {
		// if (
		// 	// !element.classList.contains("slick-slide") &&
		// 	(element.classList.contains("offFocus") &&
		// 		element.classList.contains("roomDetailTitle")) ||
		// 	(element.classList.contains("offFocus") &&
		// 		element.classList.contains("viewRateBtn")) ||
		// 	(element.classList.contains("offFocus") &&
		// 		element.classList.contains("filtersLink")) ||
		// 	(element.classList.contains("offFocus") &&
		// 		element.classList.contains("compareRoomBtn")) ||
		// 	(element.classList.contains("offFocus") &&
		// 		element.classList.contains("customBtnTransparent"))
		// ) {
		// 	element.setAttribute("tabindex", "0");
		// 	element.classList.remove("offFocus");
		// 	// element.removeAttribute("tabindex");
		// }

		// // remove tabindex for element id 'roomList'
		// var roomListElement = document.getElementById("roomList");
		// if (roomListElement) {
		// 	roomListElement.removeAttribute("tabindex");
		// 	element.classList.remove("offFocus");
		// }

		// remove tabindex from class id 
		if (
			element.classList.contains("offFocus") ||
			element.classList.contains("pageTitle") ||
			element.classList.contains("filtersCompareBar") ||
			element.classList.contains("roomsList") ||
			element.classList.contains("roomListImages") ||
			element.classList.contains("singleRoomsList")
		) {
			element.removeAttribute("tabindex");
			// element.classList.remove("offFocus");
		}
	});




	// set tabindex = '-1' for Video tag
	var videoElements = element.querySelectorAll("video");
	videoElements.forEach(function (videoElement) {
		videoElement.setAttribute("tabindex", "-1");
	});



	var fullImgModalElements = element.querySelectorAll(".dBlock.fullImgModal");
	fullImgModalElements.forEach(function (fullImgEle, index) {
		if (index >= 1) {
			fullImgEle.setAttribute("tabindex", "0");
		}

		var parentElement = fullImgEle.parentElement;
		if (parentElement) {
			parentElement.setAttribute("tabindex", "-1");
		}
	});


	// innerImage;
	var innerImgElements = element.querySelectorAll(".innerImage");
	innerImgElements.forEach(function (innerImgEle) {
		innerImgEle.setAttribute("tabindex", "-1");
	});

}

// reset tab index of footer and its children
export const resetFooterTabindex = (footerElement) => {
	if (footerElement) {
		footerElement.removeAttribute("tabindex");
	}

	const childElements = footerElement.children;
	for (let i = 0; i < childElements.length; i++) {
		if (childElements[i].classList.contains("offFocus")) {
			childElements[i].removeAttribute("tabindex");
			childElements[i].classList.remove("offFocus");
		}

		resetFooterTabindex(childElements[i]);
	}
};

// Trap Tab focus on Cart & Header area only
export const langPopupTrapTabFocusWithinSelection = () => {
	const parentElement = document.querySelector(".mainContent");
	const childElementsWithTabIndex0 = Array.from(
		parentElement.querySelectorAll('[tabindex="0"]')
	);
	const sliderDivElement = document.querySelectorAll(".slick-arrow");
	const plusIconDivElement = document.querySelectorAll(".plusIcon");
	const siteLogo = document.getElementById("siteLogo");
	const languageBtn = document.getElementById("languageBtn");
	const footerDivElement = document.getElementById("mainFooter");

	childElementsWithTabIndex0.forEach((el) => {
		addTabindexToMinusOne(el);
	});

	plusIconDivElement.forEach((el) => {
		addTabindexToMinusOne(el)
	});

	sliderDivElement.forEach((el) => {
		addTabindexToMinusOne(el)
	});

	addTabindexToMinusOne(siteLogo);
	addTabindexToMinusOne(languageBtn);

	changeTabindexToMinusOne(footerDivElement);
};

export const addTabindexToMinusOne = (element) => {
	element.setAttribute("tabindex", "-1");
	element.classList.add("offFocus");
}


export const langPopupReleaseTabFocusWithinSelection = () => {
	// select elements which having class 'offFocus' and also having tabindex = '-1'
	const elementsWithTabIndex = document.querySelectorAll(
		'.offFocus[tabindex="-1"]'
	);

	// remove class 'offFocus' and set tabindex = 0
	elementsWithTabIndex.forEach((element) => {
		element.classList.remove("offFocus");
		element.setAttribute("tabindex", "0");
	});

	removeFooterAndChildrenTabindex();
}


export const removeFooterAndChildrenTabindex = () => {
	// remove tabindex attribute from all footer's children having tabindex = 0
	const footerElement = document.getElementById("mainFooter");
	const childElementsWithTabIndexZero = Array.from(
		footerElement.querySelectorAll('[tabindex="0"]')
	);

	// remove tabindex attribute
	childElementsWithTabIndexZero.forEach((element) => {
		element.removeAttribute("tabindex");
	});
}

export const replaceEmoji = (value) => {
	return value?.replace(/[^\p{L}\p{N}\s\-!"#$%&'()*+,.:;<=>?@\[\\\]^_`{|}~]/gu, '')
}

export const MAX_DATE = dayjs(new Date("2026-05-01").toISOString().slice(0, 10))

export const isInView = (el) => {
	let box = el?.getBoundingClientRect();
	return box?.top <= window?.innerHeight && box?.bottom >= 0;
};


// translate PREFIX values and return updated array
export const TranslatedPrefix = (arr, lang) => {
	const { t } = useTranslation();
	const updatedArray = [];

	for (let i = 0; i < arr[lang].length; i++) {
		updatedArray.push(t(arr[lang][i]));
	}
	return updatedArray;
}

export const notShowCrossSellSiteId = [
	SITE_ID_LIST[3],
	SITE_ID_LIST[4],
	SITE_ID_LIST[6],
	SITE_ID_LIST[7]
]

export const getCurrencyHotelWise = (siteId) => {
	if ([SITE_ID_LIST[0], SITE_ID_LIST[1], SITE_ID_LIST[2], SITE_ID_LIST[5], SITE_ID_LIST[8]].includes(siteId)) {
		return 'GBP';
	} else if ([SITE_ID_LIST[4], SITE_ID_LIST[6]].includes(siteId)) {
		return 'EUR';
	} else if ([SITE_ID_LIST[3], SITE_ID_LIST[7]].includes(siteId)) {
		return 'USD';
	}
}

export const generateToken = () => {
	const ipAddress = 0
	const currentDateInSeconds = Math.floor(Date.now() / 1000);
	const fixedChars = "80D_iVz9qVORz!S";
	const random = (Math.random() + 1).toString(36).substring(0, 12);
	const siteToken = `${ipAddress}${currentDateInSeconds}${fixedChars}${random}`;
	return siteToken;
};

// get the third party policy parameter
export const getThirdPartyPolicy = () => {
	let config = localStorage.getItem("config")
		? JSON.parse(localStorage.getItem("config"))
		: [];
	let thirdPartyPolicy = config.third_party_policy
		? config.third_party_policy
		: [];

	return thirdPartyPolicy;
}

// get policy codes of given rate id
export const getPolicyCodeData = (rateId, roomRates) => {
	const rate = roomRates.find(roomRate => roomRate.rate_id === rateId);
	if (rate) {
		return rate.policy_code_data;
	} else {
		return null; // Return null or handle the case where rate_id is not found
	}
}

export const getMaxCount = (siteId) => {
	if ([SITE_ID_LIST[3], SITE_ID_LIST[7]].includes(siteId)) {
		return 6
	} else {
		return 2
	}
}

export const PREFIX =
{
	en: ["Ms", "Mrs", "Mr", "Miss", "Mx", "Dr", "Sir", "Lady", "Prof"],
	fr: ["Mr", "Mrs"]
};

export const getMinDate = (offset) => {
	if (offset && offset !== "0000-00-00") {
		let offsetMoment = moment(offset);
		let formattedFutureDate = offsetMoment.format('YYYY-MM-DD');
		return dayjs(new Date(formattedFutureDate).toISOString().slice(0, 10))
	}
}

// set booking start date after adding offset days
export const setOffsetChange = (offset) => {
	const searchParams = new URLSearchParams(document.location.search);
	const checkInFromURL = searchParams.get("checkin");
	const checkOutFromURL = searchParams.get("checkout");
	if (!(checkInFromURL || checkOutFromURL) && offset && offset !== "0000-00-00") {
		const offsetMoment = moment(offset);
		const currentCheckInDate = SessionStorage.get(KEYS.CHECK_IN_DATE);
		if (!currentCheckInDate || moment(currentCheckInDate).isBefore(offsetMoment, 'day')) {
			let formattedFutureDate;
			if (offsetMoment.isBefore(moment(), 'day')) {
				formattedFutureDate = moment().format('YYYY-MM-DD');
			} else {
				formattedFutureDate = offsetMoment.format('YYYY-MM-DD');
			}
			const updatedFutureDate = moment(formattedFutureDate).add(1, 'day').format('YYYY-MM-DD');
			SessionStorage.set(KEYS.CHECK_IN_DATE, formattedFutureDate);
			SessionStorage.set(KEYS.CHECKOUT_DATE, updatedFutureDate);
		}
	}
}

// Render audio eye code
export const renderAudioEyeCode = (siteId) => {
	var audioEyeHashCode = null;
	const script = document.createElement('script');
	script.type = 'text/javascript';
	script.async = true;

	if (siteId === SITE_ID_LIST[0]) {
		// Claridges
		audioEyeHashCode = '18c9f0fb4c2e50d050c7c4709fc3d41a';
	} else if (siteId === SITE_ID_LIST[1]) {
		// The Berkley
		audioEyeHashCode = 'e816dbacdd5ac7347056f45d9cd912ca';
	} else if (siteId === SITE_ID_LIST[2]) {
		// The Connaught
		audioEyeHashCode = '4aa343b75ece98e3ec2bf430ba5fe2db';
	} else if (siteId === SITE_ID_LIST[3] || siteId === SITE_ID_LIST[7]) {
		// The Maybourne Beverly Hills
		audioEyeHashCode = 'e816dbacdd5ac7347056f45d9cd912ca';
	} else if (siteId === SITE_ID_LIST[4] || siteId === SITE_ID_LIST[6]) {
		// The Maybourne Riviera
		audioEyeHashCode = '8f95610aca885c19e9094372f18b5c31';
	} else if (siteId === SITE_ID_LIST[5] || siteId === SITE_ID_LIST[8]) {
		// The Emory
		audioEyeHashCode = '865321e50fdf27904b943f04fcf270fa';
	}


	script.innerHTML = `
      !function() {
        var b = function() {
          window.__AudioEyeSiteHash = '${audioEyeHashCode}';
          var a = document.createElement("script");
          a.src = "https://wsmcdn.audioeye.com/aem.js";
          a.type = "text/javascript";
          a.setAttribute("async", "");
          document.getElementsByTagName("body")[0].appendChild(a);
        };
        "complete" !== document.readyState ? window.addEventListener ? window.addEventListener("load", b) : window.attachEvent && window.attachEvent("onload", b) : b();
      }();
    `;

	document.head.appendChild(script);

	return () => {
		document.head.removeChild(script);
	};
}

export const renderpingdomscript = (siteId) => {
	if (window.location.hostname.includes('bookings.')) {

		var pingdomcode = null;
		if (siteId === SITE_ID_LIST[0]) {
			// Claridges
			pingdomcode = '659bcc3beff3250012000573';
		} else if (siteId === SITE_ID_LIST[1]) {
			// The Berkley
			pingdomcode = '659bcc6a68cac4001200059c';
		} else if (siteId === SITE_ID_LIST[2]) {
			// The Connaught
			pingdomcode = '659bcc4feff3250012000574';
		} else if (siteId === SITE_ID_LIST[7]) {
			// The Maybourne Beverly Hills
			pingdomcode = '659bcbe868cac4001200059a';
		} else if (siteId === SITE_ID_LIST[6]) {
			// The Maybourne Riviera
			pingdomcode = '659bcc0eeff3250012000572';
		} else if (siteId === SITE_ID_LIST[8]) {
			// The Emory
			pingdomcode = '659bcc2568cac4001200059b';
		}
		const script = document.createElement('script');
		script.src = `//rum-static.pingdom.net/pa-${pingdomcode}.js`;
		script.async = true;

		document.body.appendChild(script);

		return () => {
			//document.body.removeChild(script);
		};
	}
}

export const seasonalClosureCheck = (day, siteId) => {
	// Create a new Date object for the start date (January 20, 2025 at 00:00:00)
	// Note: In JavaScript, months are zero-based, meaning January is 0 and December is 11.
	const startDate = new Date(2025, 0, 20, 0, 0, 0);
	const additionalStartDate = new Date(2025, 10, 16, 0, 0, 0)

	// Create a new Date object for the end date (June 16, 2025 at 00:00:00)
	// Note: Here, 5 represents June because the months are zero-based.
	const endDate = new Date(2025, 5, 16, 0, 0, 0);

	const additionalEndDate = new Date(2026, 2, 16, 0, 0, 0)

	// Create a new Date object for the date to check. If 'day.$d' exists, use that, otherwise use 'day'
	const date = new Date(day.$d || day);

	// Use Moment.js to check if 'date' is between 'startDate' and 'endDate' (inclusive)
	// The 'null' parameter is for granularity (not used here), and '[]' means to include the start and end dates in the range
	return SITE_ID_LIST_HASH?.mbr?.includes(siteId?.toString()) && (moment(date).isBetween(startDate, endDate, null, '[]') || moment(date).isBetween(additionalStartDate, additionalEndDate, null, '[]'));
}

export const SITE_ID_LIST_HASH = {
	claridges: "31235",
	berkley: "31236",
	connaught: "31237",
	mbh: ["10203058", "55598"],
	mbr: ["10203059", "55597"],
	emory: ["10203099", "55633"],
}
