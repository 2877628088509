import React, { useState, useEffect, useContext } from "react";
import { useLocation } from 'react-router-dom';
import Spinner from "../../../includes/spinner/Spinner";
import { useRoomListHook } from "../../hooks/useRoomListHook";
import Cart from "../cart";
import CompareRooms from "./compareRooms";
import FilterRooms from "./filterRooms";
import OfferList from "./offerList";
import RoomListDetails from "./roomListDetails";
import AppContext from "../../utils/appContext";
import CompareRoomsFooter from "./compareRoomsFooter";
import NoDataFound from "../common/NoDataFound";
import { useTranslation } from "react-i18next"
import Loader from "../common/Loader";
import { KEYS } from "../../utils/constant";
import { LocalStorage, SessionStorage } from "../../utils/storage";
import Moment from "moment";
import { SITE_ID_LIST_HASH } from "../../utils/utils";
import TagManager from 'react-gtm-module'

const RoomListComponent = ({ authToken, siteConfig, searchPanelData }) => {
	const {
		roomList,
		loading,
		offerList,
		viewHideRoomOffer,
		hotelData,
		currentRoom,
		availability,
		availabilityData,
		loading2,
		deepLinkNoData,
		partialSearch,
		lastSearchedData,
		inquireList,
		loading3,
		getNoAvailability,
		setViewHideRoomOffer,
		setRoomList,
		setOfferList,
		getOfferList,
		setLoading,
		getRoomList,
		setInquireList
	} = useRoomListHook({
		authToken,
		searchPanelData
	});

	const queryData = {
		checkin: SessionStorage.get(KEYS.CHECK_IN_DATE),
		checkout: SessionStorage.get(KEYS.CHECKOUT_DATE),
		//lang: LocalStorage.get(KEYS.LANG),
	}

	let location = useLocation();
	const [viewBy, setViewBy] = useState("rooms");
	const [viewStatus, setViewStatus] = useState(false)
	let checkindate = sessionStorage.getItem("checkInDate")
	let checkoutdate = sessionStorage.getItem("checkoutDate")

	const context = useContext(AppContext)
	const { t } = useTranslation()
	let currentSelectedRoom = LocalStorage.get(KEYS.CURRENT_SELECTED_ROOM);
	let cartItems = JSON.parse(SessionStorage.get(KEYS.ROOM_DATA));
	let occKey = "";
	if (currentRoom) {
		let singleRoom = cartItems.find(item => item.room == currentSelectedRoom)
		if (singleRoom) {
			occKey = singleRoom.adults + "-" + singleRoom.childrens.length + "i0";
		}
	}
	const [availableroominfo, setavailableroominfo] = useState([]);

	useEffect(() => {
		if (context.hotelData.room_occupancy !== undefined && context.hotelData.room_occupancy[occKey]) {
			setavailableroominfo(context.hotelData.room_occupancy[occKey].room);
		}
		window.scrollTo(0, 0)
	}, [context.hotelData])

	useEffect(() => {
		if (context.hotelData.hotel_name && viewHideRoomOffer === false && siteConfig.google_tag_head_code != 0 && siteConfig.google_tag_head_code != '') {
			let checkin = Moment(checkindate, "YYYY-MM-DD");
			let checkout = Moment(checkoutdate, "YYYY-MM-DD");
			let todydate = Moment(new Date(), "YYYY-MM-DD");
			let totalNights = checkout.diff(checkin, "days");
			let totalbookingwindow = checkin.diff(todydate, "days");
			let itemsArray = [];
			let ratecount = 0;
			let occdata = '';
			roomList?.map(item => {
				item?.price && item?.price?.map(rateitem => {
					rateitem.occupancies_price && rateitem.occupancies_price.map(occitem => {
						let roomItem = {
							"item_id": item.room_code,
							"item_name": item.room_name,
							"item_ratecode": rateitem.opera_code,
							"affiliation": context.hotelData.hotel_name,
							"currency": LocalStorage.get(KEYS.SELECTED_CURRENCY),
							"index": ratecount,
							"item_brand": siteConfig.site_title,
							"item_numberofadults": occitem.adult,
							"item_numberofchildren": occitem.child,
							"item_variant": rateitem.rate_name,
							"price": occitem.price,
							"quantity": 1
						}
						itemsArray.push(roomItem);
						ratecount++;
					})

				})
			});

			let cartItems = SessionStorage.getJSON(KEYS.ROOM_DATA);
			let totalGuests = 0;
			cartItems.map((item, index) => {
				totalGuests = totalGuests + parseInt(item.adults) + item.childrens.length
			});

			window.dataLayer = window.dataLayer || [];
			//TagManager.dataLayer({ ecommerce: null });
			const tagManagerArgs = {
				"event": "view_item_list",
				"ecommerce": {
					"item_list_id": "room_list",
					"item_list_name": "Room list",
					"affiliation": context.hotelData.hotel_name,
					"currency": LocalStorage.get(KEYS.SELECTED_CURRENCY),
					"item_check_in": checkindate,
					"item_check_out": checkoutdate,
					"item_hotel_ID": context.hotelData.hotel_id,
					"los": totalNights,
					"coupon": SessionStorage.get(KEYS.BOOKING_CODE) || "",
					"item_numberofguests": totalGuests,
					"language": LocalStorage.get(KEYS.I18_NEXT_LNG),
					"item_bookingwindow": totalbookingwindow,
					"items": itemsArray
				},
			};
			//TagManager.dataLayer(tagManagerArgs);
			window.dataLayer.push({ ecommerce: null });
			window.dataLayer.push(tagManagerArgs);
		}

	}, [context.hotelData])

	const lastIndex = roomList?.findIndex(item => item.sale_type == 0);
	const firstIndex = roomList?.slice().reverse().findIndex(item => item.sale_type == 1);
	const startIndex = roomList?.length - 1 - firstIndex;

	let checkoutnoavailability = context.checkoutnoavailabilityerror;
	//if(context.checkoutnoavailabilityerror === 1){
	//  context.setcheckoutnoavailabilityerror(0)
	//}

	const occupancyCheck = (room) => {
		const { adults, childrens } = cartItems[currentRoom - 1]
		const { max_adult, max_child, max_occ, min_occ } = room;
		let totalOcc = Number(adults) + Number(childrens.length)

		return totalOcc <= max_occ && totalOcc >= min_occ && (adults <= max_adult) && (childrens?.length <= max_child)
	}

	const roomListElement = (item, index) => {
		return (<RoomListDetails
			index={index}
			item={item}
			siteConfig={siteConfig}
			hotelData={hotelData}
			viewBy={viewBy} />)
	}

	const groupRoomCount = (groupRoomIds) => {
		return groupRoomIds.reduce((totalCount, item) => {
			const count = cartItems.map((item) => item.room_info.room_id).filter(value => value == parseInt(item)).length;
			return totalCount + count;
		}, 0);
	}
	


	return (
		<main className='mainContent'>
			{(loading || loading2 || loading3) && <Loader />}
			<div className='columnContainerFull roomListPage'>
				<div className='columnWrap'>
					<div className='column70 paddingTB50' id='roomList'>
						{!availability &&
							(roomList?.length || offerList?.length || inquireList?.length) ? (
							<>
								{deepLinkNoData && (
									<div className='pageTitleWrap2 paddingB30 marginTB30 noDataFoundInfo'>
										<h1 className='m0 paddingB10'>
											{t("no_data_header_2")}
										</h1>
										{SITE_ID_LIST_HASH?.mbr?.includes(siteConfig?.site_id.toString()) && new Date(checkindate) <= new Date(2025, 5, 16, 0, 0, 0) && new Date() <= new Date(2025, 5, 16, 23, 59, 59) && <p>{t("seasonal_closure")}</p>}
										{new URLSearchParams(location.search).get('view') === "ratetype" ? <p>
											{t("no_data_detail_rate_type")}
										</p> : new URLSearchParams(location.search).get('view') === "roomtype" ? <p>
											{t("no_data_detail_room_type")}</p> : <p>
											{t("no_data_detail_1")}{" "}
											<a href='mailto:reservations@claridges.co.uk'>
												{hotelData?.email}
											</a>{" "}
											{t("no_data_detail_2")}{" "}
											<a href='tel:+44 (0) 2071078862'>
												{hotelData?.phone}
											</a>{" "}
											{t("no_data_detail_3")}
										</p>}
									</div>
								)}
								{checkoutnoavailability ? (
									<div className='noRoomAvailMsg selectionNoAvail'>
										<p>
											{t("checkoutNoAvailability")}
										</p>
									</div>
								) : (
									<></>
								)}
								<h1 className='pageTitle m0 paddingB50'>
									{t("select_room")} {currentRoom}
								</h1>
								<FilterRooms
									setRoomList={setRoomList}
									setOfferList={setOfferList}
									setViewHideRoomOffer={setViewHideRoomOffer}
									getOfferList={getOfferList}
									setLoading={setLoading}
									showData={roomList?.length || offerList?.length}
									setInquireList={setInquireList}
									viewType={[viewBy, setViewBy]}
								/>

								{partialSearch && (
									<div className='noRoomAvailMsg'>
										<p>
											Room {cartItems?.length + 1} has too
											many occupants. For{" "}
											{lastSearchedData} or more people,
											please call our reservations team on{" "}
											{hotelData.phone}.
										</p>
									</div>
								)}
								{context.compareRooms ? (
									<CompareRooms />
								) : (
									<></>
								)}
								{viewHideRoomOffer ? (
									<>
										{offerList?.map((item, i) => (
											<OfferList
												index={i}
												item={item}
												siteConfig={siteConfig}
												hotelData={hotelData}
												viewBy={viewBy}
											/>
										))}
										{inquireList?.length ? (
											<div className='signatureSiteDivide'>
												{t("signature_suites")}
											</div>
										) : (
											""
										)}
										{inquireList?.map((item, i) => (
											<RoomListDetails
												item={item}
												siteConfig={siteConfig}
												hotelData={hotelData}
												viewBy={viewBy}
											/>
										))}
									</>
								) : (
									roomList?.map((item, index) =>
										availableroominfo.indexOf(
											item.room_id
										) != -1 || item.sale_type == 0 ? (
											<>
												{(item.group_room ? groupRoomCount(Object.keys(item.price[0].room_group)) < item.price[0].totalroomcount : JSON.parse(window.sessionStorage.getItem("roomData"))?.filter(({ room_info }) => (room_info.room_id == item?.room_id))?.length < item?.maxroom) || item?.sale_type === "0"?<RoomListDetails
													item={item}
													siteConfig={siteConfig}
													hotelData={hotelData}
													viewBy={viewBy}
												/>:null}
												{firstIndex > 0
													? index === startIndex &&
													index !== lastIndex && (
														<div className='signatureSiteDivide'>
															{t(
																"signature_suites"
															)}
														</div>
													)
													: ""}
											</>
										) : (
											""
										)
									)
								)}
								{context.compareRooms ? (
									<CompareRoomsFooter />
								) : (
									<></>
								)}
							</>
						) : (
							<>
								{!loading && !loading2 && (
									<>
										{SITE_ID_LIST_HASH?.mbr?.includes(siteConfig?.site_id?.toString()) && new Date(queryData?.checkin) < new Date(2025, 5, 17, 0, 0, 0) && new Date() <= new Date(2025, 5, 16, 23, 59, 59) && new Date(checkindate) > new Date(2025, 0, 20, 0, 0, 0) ? <p>{t("seasonal_closure")}</p> :
											<>
												<div className='signatureSiteDivide'>
													{t("signature_suites")}
												</div>

												{new URLSearchParams(location.search).get('view') === "ratetype" ? inquireList.filter(item => occupancyCheck(item))?.map((item, i) => (
													<RoomListDetails
														item={item}
														siteConfig={siteConfig}
														hotelData={hotelData}
														viewBy={viewBy}
													/>
												)) : roomList?.filter((item) => item?.sale_type == 0 && occupancyCheck(item))?.map((item, index) =>
													availabilityData.length === 0 ? roomListElement(item, index) : (<>
														{index < 2 ? roomListElement(item) : index > 1 && viewStatus && roomListElement(item)}
														{index === 1 && roomList?.filter((item) => item?.sale_type == 0 && occupancyCheck(item)).length > 2 && !viewStatus ? <div className="viewEventTypo" onClick={() => setViewStatus(true)}>
															{t("view_more")}
														</div> : index === roomList?.filter((item) => item?.sale_type == 0 && occupancyCheck(item)).length - 1 && viewStatus && (<div className="viewEventTypo" onClick={() => setViewStatus(false)}>
															{t("view_less")}
														</div>)}
													</>)
												)
												}
											</>}
										<NoDataFound
											availabilityData={availabilityData}
											hotelData={hotelData}
											siteId={siteConfig?.site_id}
										/>
									</>
								)}
							</>
						)}
					</div>
					<Cart
						hotelData={hotelData}
						loading={loading}
						getRoomList={getRoomList}
						siteConfig={siteConfig}
						getNoAvailability={getNoAvailability}
						availability={availability}
					/>
				</div>
			</div>
			<div
				className='cartOverlay'
				style={
					context.overlay ? { display: "block" } : { display: "none" }
				}
			/>
		</main>
	);
};

export default RoomListComponent;
