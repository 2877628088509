import React, { useState, useContext } from "react";
import parse from "html-react-parser";
import { useRoomListHook } from "../../hooks/useRoomListHook";
import { useRoomDetailsHook } from "../../hooks/useRoomDetailsHook";
import Spinner from "../../../includes/spinner/Spinner";
import {
	OnDetailPageChangeTabindexMinusOneToZero,
	OnDetailPageTrapTabFocusWithinSelection,
	capitalizeStr,
	changeTabindexMinusOneToZero,
	changeTabindexToMinusOne,
	floorPlanUrl,
	resetFooterTabindex,
	roomDescriptionDisplay,
	trapTabFocusWithinSelection,
} from "../../utils/utils";
import BedIcon from "../../styles/images/bed-icon.png";
import UserIcon from "../../styles/images/user-icon.png";
import SizeIcon from "../../styles/images/size-icon.png";
import RoomView from "../../styles/images/room-view-icon.png";
import ConnectingRoomIcon from "../../styles/images/connecting-rooms.svg";
import ArrowDown from "../../styles/images/arrow-down-d.svg";
import backarrow from "../../styles/images/back-arrow.png";
import RoomSlider from "../room-details/roomSlider";
import SingleRoomRateDetails from "./singleRoomRateDetails";
import SingleRoomFloorPlan from "./singleRoomFloorPlan";
import SingleRoomDescription from "./singleRoomDescription";
import { useNavigate } from "react-router-dom";
import { route } from "../../utils/route";
import Cart from "../cart";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AppContext from "../../utils/appContext";
import moment from "moment";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import SingleRoom360Tour from "./singleRoom360Tour";
import Loader from "../common/Loader";
import EnquireModal from "../common/Modal/enquireModal";
import SignatureSuiteEnquire from "./SignatureSuiteEnquire";

export default function RoomDetailsComponent({
	authToken,
	siteConfig,
	searchPanelData,
	room_id,
}) {
	const context = useContext(AppContext);
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { object, string, array, ref, boolean } = yup;

	const defaultValues = {
		prefix: undefined,
		firstName: undefined,
		lastName: undefined,
		phoneCode: undefined,
		phoneNumber: undefined,
		email: undefined,
		confirmEmail: undefined,
		notStayRoom: false,
		optIn: false,
		guest: [
			{
				firstName: undefined,
				lastName: undefined,
				arrivalTime: undefined,
				stayInRoom: false,
				additional: undefined,
			},
		],
		country: undefined,
		city: undefined,
		zipCode: undefined,
		addressLine1: undefined,
		addressLine2: undefined,
		nameOnCard: undefined,
		cardNumber: undefined,
		cardType: undefined,
		expiryDate: undefined,
		cvv: undefined,
		termsCheckbox: false,
		policyCheckbox: false,
	};

	const checkoutSchema = object({
		firstName: string().required(t("first_name_required")),
		lastName: string().required(t("last_name_required")),
		phoneNumber: string()
			.required(t("phone_number_required"))
			.min(6, t("invalid_phone"))
			.max(15, t("invalid_phone")),
		email: string().required(t("email_required")).email(t("invalid_email")),
		confirmEmail: string()
			.required(t("confirm_email_required"))
			.oneOf([ref("email"), null], t("match_email"))
			.email(t("confirm_email_invalid")),
		guest: array()
			.of(
				object().shape({
					firstName: string().required(t("first_name_required")),
					lastName: string().required(t("last_name_required")),
					//   arrivalTime: string().required(t("estimated_time_required")),
				})
			)
			.min(1, t("guest_details_required"))
			.required(t("guest_details_required")),
		city: string().required(t("city_required")),
		zipCode: string().required(t("zip_code_required")),
		addressLine1: string().required(t("address_1_required")),
		nameOnCard: string().required(t("card_name_required")),
		cardNumber: string()
			.min(13, t("invalid_card"))
			.max(19, t("invalid_card"))
			.required(t("card_number_required")),
		cardType: string().required(t("card_type_required")),
		expiryDate: string()
			.required(t("expiry_date_required"))
			.matches(/^(0[1-9]|1[0-2])(\/\d{0,2})?$/, t("invalid_expiry_date"))
			.test("expiry-date", t("valid_expiry_date"), function (value) {
				if (!value) return false;
				const [month, year] = value.split("/");
				const formattedMonth = month.padStart(2, "0");
				const currentDate = moment().startOf("month");
				const expiryDate = moment(
					`${formattedMonth}/01/20${year}`,
					"MM/DD/YYYY",
					true
				);
				return expiryDate.isSameOrAfter(currentDate);
			}),
		cvv: string()
			.min(3, t("invalid_cvv"))
			.max(4, t("invalid_cvv"))
			.required(t("cvv_required")),
		termsCheckbox: boolean().oneOf([true], t("terms_checkbox_required")),
		policyCheckbox: boolean().oneOf([true], t("policy_checkbox_required")),
	});

	const {
		register,
		handleSubmit,
		setValue,
		getValues,
		watch,
		clearErrors,
		setError,
		control,
		trigger,
		resetField,
		formState: { errors = {} },
	} = useForm({
		mode: "onChange",
		reValidateMode: "onChange",
		defaultValues,
		shouldFocusError: true,
		resolver: yupResolver(checkoutSchema),
	});

	// const handleSubmit = () => {};

	const onSubmit = (values) => {
		// console.log(values);
	};

	const { loading: loading3, getRoomList } = useRoomListHook({
		authToken,
		loader: false,
	});

	const { loading, setLoading, hotelData, roomDetails } = useRoomDetailsHook({
		authToken,
		searchPanelData,
		room_id,
	});
	
	const details = (keyHighlight) => {
		const descData = roomDescriptionDisplay(roomDetails?.key_highlights);
		let bedData = descData?.[0];
		const sleepData = descData?.[1];
		const roomView = descData?.[2];
		// const roomView = "sea_view,road_view,pool_view,";
		const sizeData = descData?.[3];
		const freetext = descData?.[4];
		let keyhightlightcount = 0;

		// Sanitize 'Room view' data
		let roomViewArr = roomView.split(/[:,]/); // break 'string' from ':' & ',' and convert it into array 
		roomViewArr = roomViewArr.filter((elm) => elm); // remove empty values from array
		let roomViewStr = '';   // Add 'room view' text as prefix
		
		roomViewArr?.map((element, index) => {
			element = element.trim(); // remove space from start & end of the string
			if (index > 1 && index <= roomViewArr.length) { // if element's index is not equal to first, second and last
				roomViewStr += ", ";
			}
			if (element) {
				roomViewStr += t(element);
			}
			if (index === 0) { // add ':' after first element to consider as Title
				roomViewStr += ": ";
			}
			return roomViewStr;
		});
		// Sanitize 'Room view' data


		return (
			<ul className='roomAmenities paddingB25'>
				{bedData && (
					<li key='bed'>
						<img src={BedIcon} alt='Bed' width='16' />
						{bedData}
					</li>
				)}
				{sleepData && (
					<li key='user'>
						<img src={UserIcon} alt='Users' width='16' />
						{sleepData}
					</li>
				)}
				{sizeData && (
					<li key='size'>
						<img src={SizeIcon} alt='Size' width='16' />
						{sizeData}
					</li>
				)}
				{/* Room View */}
				{/* {console.log("roomView -> " + JSON.stringify(roomView))} */}
				{roomView && (
					<li key='view'>
						<img src={RoomView} alt='Size' width='16' />
						{`${roomViewStr} ${keyHighlight?.room_attr?.additional_text?keyHighlight?.room_attr?.additional_text:""}`}
					</li>
				)}
				{/* Room View */}
				{freetext && (
					<li>
						<img
							src={ConnectingRoomIcon}
							alt={t("connecting_room")}
							width='16'
						/>
						{freetext}
					</li>
				)}
			</ul>
		);
	};

	const goToAllRooms = () => {
		navigate(route.roomList);
	};

	const [isCartShown, setIsCartShown] = useState(false);
	const [isOverlay, setIsOverlay] = useState(context.overlay);
	const handleCartClick = (event) => {
		setIsCartShown((current) => !current);

		if (isCartShown === false) {
			// Trap tab focus
			setIsCartShown(true);
			OnDetailPageTrapTabFocusWithinSelection();
			const roomSectionElement = document.getElementById("roomList");
			changeTabindexToMinusOne(roomSectionElement);
			// Trap tab focus
		} else {
			// Trap tab focus
			document.getElementById("siteLogo").focus(); // Set focus on site's logo
			const roomListDivElement = document.getElementById("roomList");
			OnDetailPageChangeTabindexMinusOneToZero(roomListDivElement);
			const footerDivElement = document.getElementById("mainFooter");
			resetFooterTabindex(footerDivElement);
			// Trap tab focus
			setIsCartShown(false);
		}

		setIsOverlay((current) => !current);
	};

	const [openEnqiry, setOpenEnquiry] = useState(false);

	const setOpenEnquiryForm = () => {
		return true;
	};

	return (
		<main className='mainContent roomDetailPage'>
			{(loading || loading3) && <Loader />}
			<div
				id='mainCartDetailWrap'
				className={`roomDetailSidebarWrap roomDetailSidebarWrapSticky ${
					isCartShown ? " showDetailSidebarWrap" : ""
				} ${context.yourStay ? "cartToggleDetail" : ""}`}
			>
				<a
					className='customBtn customBtnDark singlePageCartSidebarBtn'
					href='javascript:void(0);'
					onClick={handleCartClick}
				>
					{t("your_stay")}
				</a>
				<Cart
					onSubmitCheckout={handleSubmit(onSubmit)}
					hotelData={hotelData}
					loading={loading3}
					getRoomList={getRoomList}
					siteConfig={siteConfig}
				/>
			</div>

			<div id='roomList'>
				<section className='roomDetailTitle paddingTB50'>
					<div className='columnContainerFull'>
						<div className='columnWrap alignItemsCenter'>
							<div className='column20'>
								<div className='backToAllRoom'>
									<a
										className='dInlineBlock textUppercase'
										tabIndex='0'
										href='javascript:void(0);'
										onClick={() => goToAllRooms()}
									>
										{/* <i className='fa fa-angle-left'></i> */}
										<img src={backarrow} alt='Back Arrow' />
										{t("all_rooms")}
									</a>
								</div>
							</div>
							<div className='column60'>
								<h1 className='customTitle m0 textCenter'>
									{roomDetails?.room_name
										? capitalizeStr(roomDetails.room_name)
										: ""}
								</h1>
							</div>
							{roomDetails?.sale_type === "1" ? (
								<div className='column20'>
									<div className='goToRates textRight'>
										<a
											className='dInlineBlock textUppercase'
											tabIndex='0'
											href='#selectOffersDetails'
										>
											{t("rates")}{" "}
											<img
												src={ArrowDown}
												alt='Down Arrow'
											/>
										</a>
										<a
											className='dInlineBlock textUppercase'
											tabIndex='0'
											href='#selectOffersDetailsMobile'
										>
											{t("rates")}{" "}
											<img
												src={ArrowDown}
												alt='Down Arrow'
											/>
										</a>
										<a
											className='dInlineBlock textUppercase'
											tabIndex='0'
											href='#selectOffersDetailsMobileLandscape'
										>
											{t("rates")}{" "}
											<img
												src={ArrowDown}
												alt='Down Arrow'
											/>
										</a>
									</div>
								</div>
							) : (
								<></>
							)}
						</div>
					</div>
				</section>

				{roomDetails?.room_images ? (
					<RoomSlider
						roomName={roomDetails.room_name}
						roomImage={roomDetails?.room_images}
					/>
				) : (
					" "
				)}

				<div id='selectOffersDetails'></div>

				<section className='singleRoomInfo paddingT100'>
					<div className='columnContainerFull'>
						<div className='columnWrap'>
							<div className='column45'>
								<div className='singleRoomDetails'>
									<h3>
										{roomDetails?.room_name
											? capitalizeStr(
													roomDetails?.room_name
											  )
											: ""}
									</h3>
									{roomDetails?.room_short_desc
										? parse(roomDetails?.room_short_desc)
										: ""}

									{roomDetails?.key_highlights &&
									Object.keys(roomDetails?.key_highlights)
										.length > 0 ? (
										<>
											<h4>{t("details")}</h4>
											<div id='selectOffersDetailsMobile'></div>
											{details(
												roomDetails?.key_highlights
											)}
											<div id='selectOffersDetailsMobileLandscape'></div>
										</>
									) : (
										""
									)}

									<div className='buttonsGroup paddingT30'>
										{/* Render 'Floor Plan' Module, if Room's floor plan var found */}
										{roomDetails?.floor_plan && (
											<SingleRoomFloorPlan
												roomDetails={roomDetails}
											/>
										)}
										{/* Render '360 Tour' Module, if Room's floor plan var found */}
										{roomDetails?.enq_360 && (
											<SingleRoom360Tour
												roomDetails={roomDetails}
											/>
										)}
									</div>
								</div>
							</div>

							<div className='column55'>
								{/*  Render single room rate details, if Room's price found */}
								{roomDetails?.price?.filter(({ rate_id, roomcount }) => JSON.parse(window.sessionStorage.getItem("roomData"))?.filter(({ room_info }) => room_info.room_id == room_id)?.length < roomcount)?.map((rate, index) => (
									<SingleRoomRateDetails
										index={index}
										rate={rate}
										room={roomDetails}
										setIsCartShown={setIsCartShown}
										hotelData={hotelData}
									/>
								))}

								{/* Render 'Enquiry' Module, if Signature room*/}
								{roomDetails &&
									hotelData &&
									roomDetails?.sale_type == 0 && (
										<SignatureSuiteEnquire
											hotelData={hotelData}
											roomDetails={roomDetails}
										/>
									)}
							</div>
						</div>
					</div>
				</section>

				<section className='mobileFloorViewBtn paddingT100'>
					<div className='columnContainerFull'>
						<div className='columnWrap justifyContentCenter'>
							<div className='column60'>
								{roomDetails?.floor_plan && (
									<div className='mobileFloorPlan'>
										<label className='textCenter textUppercase'>
											{t("floor_Plan")}
										</label>
										<img
											className='single-room-floor-plan'
											alt={roomDetails?.room_name}
											src={floorPlanUrl(roomDetails)}
										/>
									</div>
								)}

								<div className='buttonsGroup'>
									{/* Render 'Floor Plan' Module, if Room's floor plan var found */}
									{roomDetails?.floor_plan && (
										<SingleRoomFloorPlan
											roomDetails={roomDetails}
										/>
									)}
									{/* Render '360 Tour' Module, if Room's floor plan var found */}
									{roomDetails?.enq_360 && (
										<SingleRoom360Tour
											roomDetails={roomDetails}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className='singleRoomContent paddingTB100'>
					{/* Render module 'Single Room Description', if Room's name & desc found */}
					{roomDetails?.room_desc && roomDetails?.room_name ? (
						<SingleRoomDescription roomDetails={roomDetails} />
					) : (
						""
					)}
				</section>
			</div>
			<div
				// className='cartOverlay'
				className={`cartOverlay ${
					isCartShown ? "" : "cartOverlayHide"
				}`}
				onClick={handleCartClick}
				// style={
				// 	context.overlay ? { display: "block" } : { display: "none" }
				// }
			/>
		</main>
	);
}
