import React from 'react';

/**
 * Component for displaying a message with a close button for a specific user group
 * @param {Object} props - The props object
 * @param {string} props.msg - The message to display
 * @param {boolean} props.isPopupVisible - Flag indicating if the message popup is visible
 * @param {function} props.setIsPopupVisible - Function to set the visibility of the message popup
 * @returns {JSX.Element} - The rendered component
 */
const MessageClosedUserGroup = React.memo(({ msg, isPopupVisible, setIsPopupVisible }) => {
    return (
        <div className="alertMessage textCenter messageClosedUserGroup" id="userAlertNotification" data-ispopupvisible={isPopupVisible} style={{ display: isPopupVisible ? 'flex' : 'none' }}>
            <div className="columnContainerFull posRelative">
                <span id="messageClosedUserGroup" dangerouslySetInnerHTML={{
                    __html: msg,
                }}></span>

                <button aria-label='Close Button' type="button" className="alertMsg closeIcon dInlineBlock" onClick={() => setIsPopupVisible(!isPopupVisible)}>Close</button>
            </div>
        </div>
    );
});

export default MessageClosedUserGroup;