import { useContext, useEffect, useState } from "react"
import { fetchMsgClosedUserGroup } from "../services/msgClosedUserGroup"
import { getMessageClosedUserGroupRequest } from "../utils/requestData";
import { useAuth } from "./useAuth";
import AppContext from "../utils/appContext";

/**
 * Custom hook to fetch and manage closed user group messages
 * @param {Object} options - Object containing authToken and siteId
 * @returns {Object} - Object containing messageClosedUserGroup, isPopupVisible, setIsPopupVisible
 */
export const useMessageClosedUserGroup = ({ authToken, siteId }) => {
    const [messageClosedUserGroup, setMessageClosedUserGroup] = useState(null);
    const [isPopupVisible, setIsPopupVisible] = useState(true);

    const { getAuth } = useAuth();
    const context = useContext(AppContext);
    let retryAttempted = false;

    /**
     * Function to fetch closed user group messages
     * @param {string} closeUpToken - Close up token for fetching messages
     */
    const fetchMessageClosedUserGroup = async (closeUpToken, language) => {
        try {
            const requestData = getMessageClosedUserGroupRequest({ siteId, language });
            const response = await fetchMsgClosedUserGroup(closeUpToken || authToken, requestData);
            if (response?.data) setIsPopupVisible(true)
            setMessageClosedUserGroup(response.data);
        } catch (error) {
            if (error.renewToken && !retryAttempted) {
                await getAuth("", "", true);
                retryAttempted = true;
            }
        }
    }

    useEffect(() => {
        if (siteId && authToken && context?.languageData) {
            fetchMessageClosedUserGroup("", context?.languageData);
        }
    }, [siteId, authToken, context?.languageData])

    return {
        messageClosedUserGroup,
        isPopupVisible,
        setIsPopupVisible
    }
}